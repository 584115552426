import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { tinyFont, superLoudFont } from '../../styles/fonts';
import colors from '../../styles/colors';
import Dialog from '../dialog/Dialog';
import Button from '../navigation/Button';
import { xlargeDistance, smallDistance } from '../../styles/distances';
import isMoreThanTwoYearsInThePast from '../../lib/isMoreThanTwoYearsInThePast';
import Link from '../navigation/Link';

const { linkColor } = colors;

const DIALOG_NONE = 'none';
const DIALOG_DELETE = 'delete';

const PROCESS_STATUS_NONE = 'none';
const PROCESS_STATUS_STARTED = 'started';
const PROCESS_STATUS_SUCCESS = 'success';
const PROCESS_STATUS_ERROR = 'error';

const ReviewEditContainer = glamorous.div({
    display: 'flex',
    justifyContent: 'space-between',
});
ReviewEditContainer.displayName = 'ReviewEditContainer';

const Action = glamorous.a(tinyFont, {
    color: linkColor,
    textDecoration: 'none',
    padding: `0 ${smallDistance}`,
});
Action.displayName = 'Action';

const DialogText = glamorous.div({
    marginBottom: xlargeDistance,
});
DialogText.displayName = 'DialogText';

const ImportantText = glamorous.span(superLoudFont);
ImportantText.displayName = 'ImportantText';

const deleteReview = async ({ fetch, config: { pathPrefix } }, reviewId) => {
    const response = await fetch(
        `${pathPrefix}/mhc/review-delete/${reviewId}`,
        {
            method: 'DELETE',
        },
    ).catch(() => {});
    return Boolean(response.ok);
};

const handleDeletion = async (context, reviewId, setProcessStatus) => {
    setProcessStatus(PROCESS_STATUS_STARTED);
    const success = await deleteReview(context, reviewId);
    setProcessStatus(success ? PROCESS_STATUS_SUCCESS : PROCESS_STATUS_ERROR);
};

const getDeletionDialogText = (processStatus) => {
    switch (processStatus) {
        case PROCESS_STATUS_SUCCESS:
            return (
                'Sie haben diese Bewertung soeben entfernt. Ihr Beitrag wird nicht veröffentlicht bzw. verschwindet ' +
                'spätestens nach 24 Stunden unwiederbringlich von unseren Seiten.'
            );
        case PROCESS_STATUS_ERROR:
            return 'Die Entfernung konnte nicht gestartet werden. Bitte versuchen Sie es später erneut.';
        default:
            return (
                'Wenn Sie diese Bewertung entfernen, wird sie nicht veröffentlicht bzw. verschwindet spätestens ' +
                'nach 24 Stunden von unseren Seiten.'
            );
    }
};

const closeDialog = (context, setCurrentDialog, processStatus) => {
    if (processStatus === PROCESS_STATUS_SUCCESS) {
        const window = context.window;
        window.scrollTo(0, 0);
        window.location.reload();
    } else {
        setCurrentDialog(DIALOG_NONE);
    }
};

const shouldShowButton = (processStatus) => {
    return (
        processStatus === PROCESS_STATUS_NONE ||
        processStatus === PROCESS_STATUS_STARTED
    );
};

const renderDialog = (
    context,
    wordings,
    handleProcess,
    { setCurrentDialog, processStatus },
) =>
    h(
        Dialog,
        {
            title: 'Achtung!',
            onClose: () =>
                closeDialog(context, setCurrentDialog, processStatus),
        },
        h('div', {}, [
            h(DialogText, wordings.text),
            shouldShowButton(processStatus) &&
                h(
                    Button,
                    {
                        onClick: handleProcess,
                        isSecondary: true,
                        isInactive: processStatus === PROCESS_STATUS_STARTED,
                    },
                    wordings.label,
                ),
        ]),
    );

const renderAction = (label, setCurrentDialog, dialog) =>
    h(
        Action,
        {
            onClick: (event) => {
                setCurrentDialog(dialog);
                event.preventDefault();
                return false;
            },
            href: '',
            target: '_blank',
        },
        label,
    );

const isReviewEditable = (review, currentDate) => {
    return (
        review.status === 'PUBLISHED' &&
        !isMoreThanTwoYearsInThePast(currentDate, review.travelDate)
    );
};

const ReviewSelfService = (review, context) => {
    const [currentDialog, setCurrentDialog] = useState(DIALOG_NONE);
    const [processStatus, setProcessStatus] = useState(PROCESS_STATUS_NONE);

    return h(ReviewEditContainer, [
        isReviewEditable(review, context.getCurrentDate()) &&
            h(
                Link,
                {
                    href: `/hrd/${review.id}`,
                    css: { ...tinyFont, padding: `0 ${smallDistance}` },
                },
                'Bewertung bearbeiten',
            ),
        renderAction('Bewertung entfernen', setCurrentDialog, DIALOG_DELETE),

        currentDialog === DIALOG_DELETE &&
            renderDialog(
                context,
                {
                    label: 'Jetzt Bewertung unwiederbringlich entfernen',
                    text: getDeletionDialogText(processStatus),
                },
                () => handleDeletion(context, review.id, setProcessStatus),
                { setCurrentDialog, processStatus },
            ),
    ]);
};

ReviewSelfService.propTypes = {
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
    hotel: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    travelDate: PropTypes.number.isRequired,
};

ReviewSelfService.contextTypes = {
    fetch: PropTypes.func.isRequired,
    config: PropTypes.shape({
        pathPrefix: PropTypes.string.isRequired,
    }).isRequired,
    window: PropTypes.object.isRequired,
    getCurrentDate: PropTypes.func.isRequired,
};

export default ReviewSelfService;
