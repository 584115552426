import { Component } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { oneLine } from 'common-tags';

import Dialog from '../dialog/Dialog';
import ButtonLink from '../navigation/ButtonLink';
import Link from '../navigation/Link';
import colors from '../../styles/colors';
import {
    smallDistance,
    mediumDistance,
    largeDistance,
} from '../../styles/distances';
import {
    bigFont,
    headlineFont,
    loudFont,
    subheadlineFont,
} from '../../styles/fonts';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';

const { secondaryBrandColor } = colors;

const PROFILE_PICTURE_TRIGGER_STORAGE_KEY = 'profilePictureTrigger';

const Content = glamorous.div({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${mediumDistance}`,
});

const SubHeadLine = glamorous.p(
    {
        textAlign: 'center',
        marginBottom: largeDistance,
    },
    headlineFont,
);

const ProfilePictureIllustration = glamorous.img({
    width: '100%',
    maxWidth: pxToRem(300),
    marginBottom: smallDistance,
});

const Description = glamorous.p(
    {
        marginBottom: mediumDistance,
        [applyOnTabletAndUp]: subheadlineFont,
    },
    bigFont,
);

const Separator = glamorous.div({
    ':after': {
        display: 'block',
        content: '""',
        width: '5rem',
        borderBottom: `3px solid ${secondaryBrandColor}`,
        margin: `0 auto ${largeDistance}`,
    },
});

const ButtonContainer = glamorous.div({
    display: 'flex',
    marginBottom: mediumDistance,
    width: '100%',
    maxWidth: pxToRem(264),

    [applyOnTabletAndUp]: {
        maxWidth: pxToRem(306),
    },
});

const buttonLinkCss = {
    justifyContent: 'center',
    width: '100%',
};

class ProfilePictureTrigger extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpened: false,
        };

        this.closeUploadPictureDialog =
            this.closeUploadPictureDialog.bind(this);
    }

    componentDidMount() {
        try {
            const { localStorage, getCurrentDate } = this.context;
            const localStorageValue = this.context.localStorage.getItem(
                PROFILE_PICTURE_TRIGGER_STORAGE_KEY,
            );
            const profilePictureTriggerData = JSON.parse(localStorageValue);
            if (
                !(profilePictureTriggerData && profilePictureTriggerData.opened)
            ) {
                localStorage.setItem(
                    PROFILE_PICTURE_TRIGGER_STORAGE_KEY,
                    JSON.stringify({
                        ...profilePictureTriggerData,
                        opened: getCurrentDate().getTime(),
                    }),
                );
                this.setState({ dialogOpened: true });
                this.context.trackEvent({
                    event: 'event',
                    eventCategory: 'MHC',
                    eventAction: 'profile picture trigger',
                    eventLabel: 'opened',
                });
            }
        } catch (err) {
            // ignore errors
        }
    }

    closeUploadPictureDialog() {
        this.setState({
            dialogOpened: false,
        });
    }

    render() {
        if (this.state.dialogOpened) {
            return h(
                Dialog,
                {
                    onClose: this.closeUploadPictureDialog,
                    medium: 12,
                    large: 12,
                },
                [
                    h(Content, [
                        h(
                            SubHeadLine,
                            'Bitte laden Sie ein Profilbild von sich hoch.',
                        ),
                        h(Separator),
                        h(ProfilePictureIllustration, {
                            src: `${this.context.config.assetsPath}/myHolidayCheck/profile-picture-trigger.svg`,
                        }),
                        h(
                            Description,
                            oneLine`Persönliche Profilbilder schaffen Vertrauen und machen Ihre Beiträge noch wertvoller.
                            Egal ob Selfie, Landschaft, Familien- oder Haustierbild – Hauptsache es gefällt Ihnen.`,
                        ),
                        h(
                            ButtonContainer,
                            {
                                onClick: () => {
                                    this.context.trackEvent({
                                        event: 'event',
                                        eventCategory: 'MHC',
                                        eventAction: 'profile picture trigger',
                                        eventLabel: 'clicked',
                                    });
                                },
                            },
                            h(
                                ButtonLink,
                                {
                                    href: '/mhc/mein-profil',
                                    css: buttonLinkCss,
                                },
                                'Jetzt hochladen',
                            ),
                        ),
                        h(
                            Link,
                            {
                                onClick: this.closeUploadPictureDialog,
                                style: loudFont,
                            },
                            'Später',
                        ),
                    ]),
                ],
            );
        }
        return null;
    }
}

ProfilePictureTrigger.contextTypes = {
    localStorage: PropTypes.object,
    getCurrentDate: PropTypes.func,
    trackEvent: PropTypes.func,
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default ProfilePictureTrigger;
