import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { waveTextL } from '../../../styles/waveText';
import Link from '../../navigation/Link';
import { waveSmMediaQuery } from '../../../styles/waveMediaQueries';

const StickyContainer = glamorous.div({
    display: 'none',
    [waveSmMediaQuery]: {
        display: 'block',
        position: 'sticky',
        top: `${options.space.xl}px`,
    },
});

const MenuContainer = glamorous.nav({
    gridArea: 'sidebar',
    borderRadius: `${options.space['2xs']}px`,
    backgroundColor: options.colors.blue100,
    padding: `${options.space.l}px`,
    height: 'max-content',
    marginLeft: `${options.space.xl}px`,
});

const HeaderContainer = glamorous.div({
    marginBottom: `${options.space.m}px`,
});
const Header = glamorous.b({
    ...waveTextL,
    fontWeight: 700,
});

const MenuList = glamorous.ul({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: `${options.space.m}px`,
});

function MenuItem({ name, href, myRef }) {
    return h('li', [
        h(
            Link,
            {
                href,
                onClick(e) {
                    e.preventDefault();
                    myRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                },
            },
            name,
        ),
    ]);
}

function BookingMenu({ entries }, { window }) {
    return h(StickyContainer, [
        h(MenuContainer, [
            h(HeaderContainer, [h(Header, 'Buchung verwalten')]),
            h('div', [
                h(
                    MenuList,
                    entries.map((entry) =>
                        h(MenuItem, {
                            name: entry.name,
                            href: `${window.location.href}#${entry.sectionId}`,
                            key: entry.id,
                            myRef: entry.myRef,
                        }),
                    ),
                ),
            ]),
        ]),
    ]);
}

BookingMenu.propTypes = {
    entries: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            sectionId: PropTypes.string.isRequired,
            myRef: PropTypes.shape({ current: PropTypes.any }),
        }),
    ),
};
BookingMenu.contextTypes = {
    window: PropTypes.object.isRequired,
};
export default BookingMenu;
