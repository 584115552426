import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveFontL, waveHeadline2XSSlim } from '../../../styles/waveHeadlines';
import { useCancellationContext } from '../context/CancellationContext';
import cancellationReasonConfig from './cancellationReasonConfig';

const RetentionContainer = glamorous.div({
    marginBottom: options.space['3xl'],
});

const RetentionHeader = glamorous.div({
    ...waveHeadline2XSSlim,
    marginBottom: options.space['2xs'],
});
const RetentionFooter = glamorous.div({
    ...waveFontL,
    '> a': {
        textDecoration: 'none',
    },
});
RetentionFooter.displayName = 'RetentionFooter';

const RetainMembership = () => {
    const { getCancellationReason, trackEventForPremium } =
        useCancellationContext();
    const selectedCancellationReason = getCancellationReason();

    if (!cancellationReasonConfig[selectedCancellationReason]) {
        return null;
    }

    return h(
        RetentionContainer,
        {
            onClick: (e) => {
                if (e.target.tagName === 'A') {
                    trackEventForPremium({
                        event: {
                            eventAction: `Click-${e.target.href}`,
                            eventLabel: selectedCancellationReason,
                        },
                    });
                }
            },
        },
        [
            h(
                RetentionHeader,
                cancellationReasonConfig[selectedCancellationReason].header,
            ),
            h(RetentionFooter, {
                dangerouslySetInnerHTML: {
                    __html: cancellationReasonConfig[selectedCancellationReason]
                        .footer,
                },
            }),
            cancellationReasonConfig[selectedCancellationReason].cta &&
                h(cancellationReasonConfig[selectedCancellationReason].cta, {
                    trackingLabel: selectedCancellationReason,
                    trackEventForPremium,
                }),
        ],
    );
};

export default RetainMembership;
