import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import Link from '../../navigation/Link';
import { FONTS, superLoudFont } from '../../../styles/fonts';
import { blue, blue20, gray40, gray90 } from '../../../styles/waveColors';
import { pxToRem } from '../../../styles/unitConverter';
import useBookingContext from '../lib/useBookingContext';

const SELF_SERVICE_PORTAL_LINK_DESCRIPTION =
    'Hast du bereits Zahlungen getätigt, kannst du deren Eingang direkt ' +
    'beim Reiseveranstalter überprüfen:';

const SelfServicePortalLinkContainer = glamorous.div({
    textAlign: 'center',
});
SelfServicePortalLinkContainer.displayName = 'SelfServicePortalLinkContainer';

const SelfServicePortalLink = glamorous(Link)(superLoudFont, {
    fontFamily: FONTS.primary,
    color: blue,
    lineHeight: '1rem',
    margin: `${pxToRem(24)} 0`,
    display: 'block',
    textTransform: 'uppercase',
    textAlign: 'center',
    cursor: 'pointer',

    ':hover': {
        color: blue20,
    },
});
SelfServicePortalLink.displayName = 'SelfServicePortalLink';

const SelfServicePortalLinkDescription = glamorous.div({
    textAlign: 'start',
    padding: `0 ${pxToRem(24)} ${pxToRem(16)} ${pxToRem(24)}`,
    color: gray40,
    borderBottom: `1px solid ${gray90}`,
});
SelfServicePortalLinkDescription.displayName =
    'SelfServicePortalLinkDescription';

const LinkIcon = glamorous.img({
    width: pxToRem(16),
    height: pxToRem(16),
    marginLeft: pxToRem(6),
    verticalAlign: 'text-top',
    color: blue,

    ':hover': {
        fill: blue20,
    },
});

LinkIcon.displayName = 'LinkIcon';

const SelfServicePortalInfo = (
    { selfServicePortalLink },
    { config: { assetsPath } },
) => {
    const { trackEventWithBooking } = useBookingContext();

    return h(SelfServicePortalLinkContainer, {}, [
        h(
            SelfServicePortalLinkDescription,
            SELF_SERVICE_PORTAL_LINK_DESCRIPTION,
        ),
        h(
            SelfServicePortalLink,
            {
                href: selfServicePortalLink,
                target: '_blank',
                onClick: () => {
                    trackEventWithBooking({
                        event: 'event',
                        eventCategory: 'mybooking',
                        eventAction: 'click',
                        eventLabel: 'tourOperatorPaymentSite',
                    });
                },
            },
            [
                h('span', 'Zahlungsstatus einsehen'),
                h(LinkIcon, {
                    src: `${assetsPath}/myBookings/link.svg`,
                    alt: 'Link zu einer externen Webseite',
                }),
            ],
        ),
    ]);
};

SelfServicePortalInfo.propTypes = {
    selfServicePortalLink: PropTypes.string.isRequired,
};

SelfServicePortalInfo.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default SelfServicePortalInfo;
