import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import BookingSectionHeadline from './BookingSectionHeadline';
import TourOperatorPrice from './payment/TourOperatorPrice';
import TourOperatorPriceQuery from './payment/TourOperatorPriceQuery';
import AdditionalServices from './payment/AdditionalServices';
import {
    getCancelledTravelText,
    getFallbackHint,
    getFallbackIntroText,
} from './lib/paymentInformationTexts';
import { hasStarted } from '../../../common/lib/bookingHelper';
import { pxToRem } from '../../styles/unitConverter';
import { black, gray40, gray90 } from '../../styles/waveColors';
import { secondaryFont } from '../../styles/fonts';
import SelfServicePortalInfo from './payment/SelfServicePortalInfo';
import PremiumServices from './payment/PremiumServices';

const { PTSansFamily, UITextL } = secondaryFont;

const PaymentInfo = glamorous.div(PTSansFamily, UITextL, {
    textAlign: 'start',
    padding: `0 ${pxToRem(24)}`,
    marginBottom: pxToRem(16),
    color: black,
});
PaymentInfo.displayName = 'PaymentInfo';

const FallbackHint = glamorous.div(PTSansFamily, {
    textAlign: 'start',
    padding: `0 ${pxToRem(24)}`,
    marginBottom: pxToRem(16),
    color: gray40,
});
FallbackHint.displayName = 'FallbackHint';

const TourOperatorPriceContainer = glamorous.div({
    border: `1px solid ${gray90}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
});

const renderFallback = ({ payment, tourOperatorPrice, tourOperator }) => {
    const { amount, currencyCode } = tourOperatorPrice;
    const fallbackHint = getFallbackHint(payment.type);
    return h(
        TourOperatorPrice,
        { amount, currencyCode, trackingName: 'fallback' },
        [
            h(PaymentInfo, getFallbackIntroText(tourOperator.name)),
            h(FallbackHint, fallbackHint),
        ],
    );
};

const renderCancelled = ({ tourOperatorPrice }) => {
    const { amount, currencyCode } = tourOperatorPrice;
    const cancelledText = getCancelledTravelText();
    return h(
        TourOperatorPrice,
        {
            amount,
            currencyCode,
            isEverythingPaid: false,
            trackingName: 'cancelled-travel',
            isCancelled: true,
        },
        [h(PaymentInfo, cancelledText)],
    );
};

const renderOnlyTourOperatorPrice = (booking, currentDate) => {
    const {
        endDate,
        tourOperatorPrice: { amount, currencyCode },
    } = booking;

    const hasTravelEnded = new Date(endDate) < currentDate;
    const trackingName = hasTravelEnded ? 'past-travel' : 'ongoing-travel';

    return h(TourOperatorPrice, { amount, currencyCode, trackingName });
};

const renderTourOperatorPrice = (booking, currentDate, isCancelled) => {
    if (isCancelled) {
        return renderCancelled(booking);
    }

    const {
        tourOperatorPrice: { dueDate, deposit },
    } = booking;
    const hasPaymentConditions = dueDate && deposit;

    if (hasStarted(booking, currentDate)) {
        return renderOnlyTourOperatorPrice(booking, currentDate);
    }

    // We should rethink the logic as querying the tour operator does not depend on the data we store
    if (hasPaymentConditions) {
        return h(TourOperatorPriceQuery, { booking });
    }

    return renderFallback(booking);
};

const tourOperatorPortalProvidesPaymentInformation = (features) => {
    return features.some((feature) => feature.key === 'PAYMENT_INFORMATION');
};

function PaymentInformation(
    { booking, myRef, setWillRender },
    { getCurrentDate },
) {
    const { services, bookingType, id: bookingId } = booking;

    const isCancelled = bookingType === 'CANCELLATION';
    const showSelfServicePortalLink = booking.tourOperator.selfServicePortal
        ? tourOperatorPortalProvidesPaymentInformation(
              booking.tourOperator.selfServicePortal.features,
          )
        : false;

    useEffect(() => setWillRender(true), []);

    return h(Fragment, [
        h(
            BookingSectionHeadline,
            { innerRef: myRef },
            'Deine Zahlungsinformation',
        ),
        h(TourOperatorPriceContainer, [
            renderTourOperatorPrice(booking, getCurrentDate(), isCancelled),
            showSelfServicePortalLink &&
                h(SelfServicePortalInfo, {
                    selfServicePortalLink:
                        booking.tourOperator.selfServicePortal.url,
                }),
        ]),
        h(AdditionalServices, { services }),
        h(PremiumServices, { services, bookingId }),
    ]);
}

PaymentInformation.propTypes = {
    booking: PropTypes.shape({
        bookingType: PropTypes.string.isRequired,
        tourOperator: PropTypes.shape({
            name: PropTypes.string,
        }).isRequired,
        tourOperatorPrice: PropTypes.shape({
            amount: PropTypes.number.isRequired,
            currencyCode: PropTypes.string.isRequired,
            dueDate: PropTypes.string,
            deposit: PropTypes.shape({
                amount: PropTypes.number.isRequired,
                dueDate: PropTypes.string,
            }),
        }).isRequired,
        services: PropTypes.array.isRequired,
    }).isRequired,
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

PaymentInformation.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
};

PaymentInformation.displayName = 'PaymentInformation';

export default PaymentInformation;
