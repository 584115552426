/* eslint-disable camelcase */

import FuturePeriodDiscountButton from '../FuturePeriodDiscountButton';

const cancellationReasonConfig = {
    premium_too_expensive: {
        header: 'Ist Dir die Mitgliedschaft zu teuer?', // ,
        footer: 'Verpasse nicht unser exklusives Angebot für Dich! Verlängere jetzt für nur 49€ statt 89€!',
        cta: FuturePeriodDiscountButton,
    },
    no_travel_planned: {
        header: 'Keine Reise geplant?',
        footer: "Kein Problem! <b>Ab dem zweiten Mitgliedsjahr erhältst Du zusätzlich 2x 50 € Aktionsgutscheine, die Du auch für Kurztrips ohne Mindestreisepreis nutzen kannst.</b>  Falls Du noch nicht weißt wohin, findest Du die aktuell besten Deals immer <a href='/deals'>hier.</a>",
    },
    dissatisfied_with_service: {
        header: 'Nicht zufrieden mit unserem Service?',
        footer: "Gib uns gerne <a href='/premium/feedback'>hier</a> Feedback, was wir an unserem Service verbessern können. Wir melden uns dann bald möglichst bei Dir zurück. ",
    },
    dissatisfied_with_benefits: {
        header: 'Du bist unzufrieden mit den Vorteilen?',
        footer: "Wir bedauern zu hören, dass Du mit den Vorteilen nicht zufrieden bist. Wir möchten sicherstellen, dass Du das Beste aus Deiner Mitgliedschaft herausholst. Gib uns deshalb gerne <a href='/premium/feedback'>hier</a> Feedback was Dir fehlt!<div><b>Wusstest Du zum Beispiel, dass Du ab dem zweiten Jahr zusätzlich 2x 50 € Gutscheine zusätzlich erhältst, die Du auch für kurze Reisen ohne Mindestreisepreis nutzen kannst?</b></div>",
    },
    not_intended_to_subscribe: {
        header: 'Du wolltest kein Abo abschließen?',
        footer: 'Wir bedauern, dass du nicht beabsichtigt hast, ein Abo abzuschließen. Unsere Vorteile lassen sich jedoch nur im Rahmen eines Abo-Modells steigern. <b>Ab dem zweiten Jahr erhalten Mitglieder deshalb z.B. zwei zusätzliche 50 Euro Gutscheine, die auch für Reisen unter 490 Euro genutzt werden können.</b>',
    },
    vouchers_not_redeemed: {
        header: 'Du hast keinen Gutschein erhalten?',
        footer: "Wir bedauern, dass Du Deinen Gutschein nicht erhalten hast. Bitte überprüfe, ob Du Deine Kontodaten für die Auszahlung in Deinem <a href='/mhc/meine-gutscheine'>meinHolidayCheck-Bereich</a> hinterlegt hast. Ansonsten kannst Du Dich gerne an <a href='mailto:info@premium.holidaycheck.com'>info@premium.holidaycheck.com</a> wenden und wir buchen Deinen Gutschein nach.",
    },
    no_value: {
        header: 'Du siehst keinen Mehrwert?',
        footer: "Wie schade, dass Du bisher keinen Mehrwert gesehen hast. Wusstest Du, dass sich unsere Vorteile über die Jahre steigern? <b>Ab dem zweiten Mitgliedsjahr erhältst Du zusätzlich 2x 50 € Aktionsgutscheine, die Du auch für Kurztrips ohne Mindestreisepreis nutzen kannst.</b> <div>Außerdem bauen wir die Vorteile kontinuierlich für Dich aus. Sollte Dir etwas fehlen, gib uns gerne <a href='/premium/feedback'>hier</a> Feedback.<div>  ",
    },
    dissatisfied_with_HolidayCheck: {
        header: 'Du bist unzufrieden mit HolidayCheck?',
        footer: "Es tut uns leid zu hören, dass du mit HolidayCheck unzufrieden bist. Wir möchten die Gelegenheit nutzen, um Deine Erfahrung zu verbessern. Gib uns gerne <a href='/premium/feedback'>hier</a> Feedback, was wir für Dich verbessern können",
    },
    travel_cancelled: {
        header: 'Deine Reise wurde storniert?',
        footer: "Es ist bedauerlich, dass deine Reise storniert wurde. Dein Gutschein wird Deinem Konto wieder gutgeschrieben und kann natürlich erneut von Dir genutzt werden. Entdecke jetzt unsere aktuellen <a href='/deals'>Deals</a>, um schnellstmöglich einen Ersatz für Deine Reise zu finden. Möchtest Du noch mehr über unsere zusätzlichen Rabatte bei exklusiven Premium-Partnern erfahren? Dann finde hier alle Vorteile.",
    },
    other: {
        header: 'Willst Du uns wirklich verlassen?',
        footer: 'Wir haben bemerkt, dass Du "Sonstiges" als Grund für deine Kündigung angegeben hast. Wir möchten sicherstellen, dass wir Deine Bedenken ernst nehmen und Deine Erfahrung verbessern. <div>Gib uns gerne <a href=\'/premium/feedback\'>hier</a>, was wir für Dich verbessern können.</div',
    },
};

export default cancellationReasonConfig;
