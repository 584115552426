import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { oneLine } from 'common-tags';

import UnloadPrompt from './UnloadPrompt';

const fallbackPromptMessage = oneLine`Ihre Änderungen wurden noch nicht gespeichert, sind Sie sicher,
    dass Sie diese Seite verlassen wollen?`;

class UnsavedPrompt extends Component {
    renderUnloadPrompt() {
        if (this.props.when) {
            return h(UnloadPrompt, {
                message: fallbackPromptMessage,
            });
        }
        return null;
    }

    render() {
        return h([this.renderUnloadPrompt()]);
    }
}

UnsavedPrompt.propTypes = {
    when: PropTypes.bool,
};

UnsavedPrompt.defaultProps = {
    when: false,
};

export default UnsavedPrompt;
