import { useEffect, useState } from 'react';
import moment from 'moment/moment';

import fetchUpcomingTravelTime from '../../../../fetchers/fetchUpcomingTravelTime';
import fetchRentalcarBookings from '../../../../fetchers/fetchRentalcarBookings';

const sortAndFilterBookings = (bookings, now) => {
    return bookings
        .filter((booking) => moment(booking.dropoffDatetime).isAfter(now))
        .sort((booking, compareBooking) => {
            const pickupMoment = moment(booking.pickupDatetime);
            const dropoffMoment = moment(booking.dropoffDatetime);
            if (now.isBetween(pickupMoment, dropoffMoment)) {
                return -1;
            }

            return moment(compareBooking.pickupDatetime).diff(pickupMoment);
        });
};

export function useFetchRentalCarBooking(context) {
    const { getCurrentDate } = context;

    const [bookings, setBookings] = useState([]);
    const [hasHcTravel, setHasHcTravel] = useState(false);

    useEffect(() => {
        const fetchBookings = async () => {
            const travelResponse = await fetchUpcomingTravelTime(context);

            const { startDate, endDate } = travelResponse
                ? travelResponse.booking
                : {};

            const [bookingsWithTravel, responseNoTravel] = await Promise.all([
                startDate && endDate
                    ? fetchRentalcarBookings(context, startDate, endDate)
                    : Promise.resolve([]),
                fetchRentalcarBookings(context),
            ]);

            setHasHcTravel(travelResponse !== null);

            const now = moment(getCurrentDate());
            // prioritize the Booking(s) with a travel date

            const activeAndFutureBookings = [
                ...sortAndFilterBookings(bookingsWithTravel, now),
                ...sortAndFilterBookings(responseNoTravel, now),
            ].filter((booking, index, allBookings) => {
                return (
                    index ===
                    allBookings.findIndex((found) => found.id === booking.id)
                );
            });

            setBookings(activeAndFutureBookings);
        };

        fetchBookings();
    }, []);

    return {
        bookings,
        hasHcTravel,
    };
}
