import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { findIdFromHotelService } from '../lib/serviceHelpers';
import { pxToRem } from '../../../styles/unitConverter';
import {
    getFormattedTimespan,
    getFormattedMonth,
    getFormattedYear,
} from '../../../../common/lib/bookingHelper';
import formatDate from '../../../lib/formatDate';
import { secondaryFont } from '../../../styles/fonts';
import { white, blackWithOpacity } from '../../../styles/waveColors';
import Link from '../../navigation/Link';
import { useBookingReviewStatusState } from './useBookingReviewStatusState';

const { UITextLLoud, UITextM } = secondaryFont;

const Container = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
});
Container.displayName = 'ThumbsContainer';

const BookingTitle = glamorous.div(UITextLLoud, ({ shouldBeWhite }) => ({
    color: shouldBeWhite ? white : 'inherit',
    marginBottom: pxToRem(4),
}));
BookingTitle.displayName = 'BookingTitle';

const BookingDate = glamorous.div(UITextM, ({ shouldBeWhite }) => ({
    color: shouldBeWhite ? white : 'inherit',
}));
BookingDate.displayName = 'BookingDate';

const ButtonsContainer = glamorous.div({
    display: 'flex',
    gap: pxToRem(20),
    marginTop: pxToRem(8),
});
ButtonsContainer.displayName = 'ThumbsButtonsContainer';

const ThumbButton = glamorous.a({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: pxToRem(56),
    height: pxToRem(56),
    borderRadius: '50%',
    boxShadow: `${blackWithOpacity(0.4)} 0 ${pxToRem(1)} ${pxToRem(4)} `,
    cursor: 'pointer',
    backgroundColor: white,
});
ThumbButton.displayName = 'ThumbButton';

const onThumbClick = (isPositive, bookingEndDate, trackEvent) => {
    trackEvent({
        event: 'event',
        eventCategory: 'mybooking',
        eventAction: 'review-past-booking',
        eventLabel: isPositive ? 'thumb-up' : 'thumb-down',
        trReturnDate: bookingEndDate,
    });

    // has to return true so that it navigates to href url as well
    return true;
};

function BookingReviewStatus(
    { booking, shouldBeWhite, assetsPath },
    { trackEvent, getCurrentDate, fetch, config },
) {
    const hotelId = findIdFromHotelService(booking.services);
    if (!hotelId || booking.bookingType === 'CANCELLATION') {
        return null;
    }
    const reviewPageUrl = `/wcf/hotelreview/variant?hotelId=${hotelId}`;

    const { hotelReview, isBookingMoreThanTwoYearsInThePast } =
        useBookingReviewStatusState({
            bookingStartDate: booking.startDate,
            bookingEndDate: booking.endDate,
            bookingHotelId: hotelId,
            fetch,
            config,
            currentDate: getCurrentDate(),
        });
    const isReviewAllowed = !hotelReview && !isBookingMoreThanTwoYearsInThePast;

    const statusMonth = getFormattedMonth(booking.endDate);
    const statusYear = getFormattedYear(booking.endDate);

    return h(Container, [
        h(BookingTitle, { shouldBeWhite }, [
            isReviewAllowed
                ? `Wie war Ihre Reise im ${statusMonth} ${statusYear}?`
                : `Ihre Reise im ${statusMonth} ${statusYear}`,
        ]),
        h(BookingDate, { shouldBeWhite }, [
            getFormattedTimespan(booking, formatDate, 'DD.MM.Y'),
        ]),
        hotelReview &&
            hotelReview.id &&
            h(
                Link,
                {
                    href: `/hrd/${hotelReview.id}`,
                    target: '_blank',
                    css: { marginTop: pxToRem(4) },
                },
                'Ihre Bewertung ansehen',
            ),
        isReviewAllowed &&
            h(ButtonsContainer, [
                h(
                    ThumbButton,
                    {
                        href: reviewPageUrl,
                        target: '_blank',
                        onClick: () =>
                            onThumbClick(true, booking.endDate, trackEvent),
                    },
                    h('img', {
                        src: `${assetsPath}/myBookings/thumb-positive.svg`,
                        alt: 'Ich war zufrieden',
                    }),
                ),
                h(
                    ThumbButton,
                    {
                        href: reviewPageUrl,
                        target: '_blank',
                        onClick: () =>
                            onThumbClick(false, booking.endDate, trackEvent),
                    },
                    h('img', {
                        src: `${assetsPath}/myBookings/thumb-negative.svg`,
                        alt: 'Ich war unzufrieden',
                    }),
                ),
            ]),
    ]);
}

BookingReviewStatus.propTypes = {
    booking: PropTypes.object.isRequired,
    shouldBeWhite: PropTypes.bool.isRequired,
    assetsPath: PropTypes.string.isRequired,
};

BookingReviewStatus.contextTypes = {
    trackEvent: PropTypes.func.isRequired,
    getCurrentDate: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
};

export default BookingReviewStatus;
