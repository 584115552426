import h from 'react-hyperscript';
import glamorous from 'glamorous';
import moment from 'moment';

import { extractFuturePeriod } from '../../lib/premium/membership';
import Hint from './Hint';
import { largeDistance, xxlargeDistance } from '../../styles/distances';
import { waveMdMediaQuery } from '../../styles/waveMediaQueries';
import formatDate from '../../lib/formatDate';
import FuturePeriodDiscountButton from './FuturePeriodDiscountButton';
import { usePremiumTrackingContext } from './context/PremiumTrackingContext';
import CampaignForMembers from './CampaignForMembers';

const DISPLAY_DISCOUNTED_RENEWAL_IN_DAYS = 7;

const MainContainer = glamorous.div({
    marginLeft: largeDistance,
    marginRight: largeDistance,
    display: 'flex',
    justifyContent: 'center',
    [waveMdMediaQuery]: {
        marginLeft: xxlargeDistance,
        marginRight: xxlargeDistance,
    },
});

const FuturePeriodOfferContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
});

const checkContractHasPendingTerminationToDisplayRenewal = (activeContract) => {
    if (
        !activeContract.contractEndDate ||
        !activeContract.hasPendingTermination
    )
        return false;

    return moment(activeContract.contractEndDate).isBetween(
        moment().startOf('day'),
        moment().add(DISPLAY_DISCOUNTED_RENEWAL_IN_DAYS, 'days').endOf('day'),
        undefined,
        '[]',
    );
};

export default function Reminder({ activeContract }) {
    const { trackEventForPremium } = usePremiumTrackingContext();

    const outstandingPaymentExists = activeContract.subscriptionPeriods.some(
        ({ periodProcessingStatus, periodStatus }) =>
            periodProcessingStatus === 'OutstandingPayment' &&
            periodStatus === 'Active',
    );

    if (outstandingPaymentExists) {
        return h(MainContainer, [
            h(Hint, {
                type: 'error',
                header: 'Hinweis zur Zahlung Deiner Mitgliedschaft',
                body:
                    'Leider konnten wir bislang keine Zahlung feststellen.' +
                    ' Wenn der Mitgliedsbeitrag nicht beglichen wird,' +
                    ' ist die Auszahlung Deiner Premium-Gutscheine ausgeschlossen.',
            }),
        ]);
    }

    const futurePeriod = extractFuturePeriod(
        activeContract.subscriptionPeriods,
    );

    const hasFuturePeriodWithDiscountCode =
        futurePeriod && futurePeriod.discountProductCode;

    if (hasFuturePeriodWithDiscountCode) {
        return h(MainContainer, [
            h(Hint, {
                type: 'success',
                header: 'Deine Premium-Mitgliedschaft wurde erfolgreich verlängert. ',
                body:
                    'Wir freuen uns, dass Du Dich für ein weiteres Jahr HolidayCheck-Premium entschieden hast.' +
                    `${futurePeriod.totalAmount !== null ? ` Für das folgende Mitgliedsjahr zahlst Du nur ${futurePeriod.totalAmount} €. ` : ' '}` +
                    `Die Rechnung erhältst Du mit beginn des Mitgliedsjahres am ${formatDate(futurePeriod.periodStartDate, 'DD.MM.YYYY')}.`,
            }),
        ]);
    }

    if (checkContractHasPendingTerminationToDisplayRenewal(activeContract)) {
        return h(MainContainer, [
            h(FuturePeriodOfferContainer, [
                h(Hint, {
                    type: 'premium',
                    header: 'Exklusives Angebot zur Verlängerung!',
                    body:
                        'Deine Mitgliedschaft läuft bald aus. Verlängere jetzt für nur 49 € anstatt 89 € und profitiere ' +
                        'auch im nächsten Jahr von unseren Vorteilen. Lass dir dieses Angebot nicht entgehen!',
                }),
                h(FuturePeriodDiscountButton, {
                    trackingLabel: 'contract-ends-soon',
                    trackEventForPremium,
                }),
            ]),
        ]);
    }

    return h(MainContainer, [h(CampaignForMembers, { trackEventForPremium })]);
}
