import h from 'react-hyperscript';
import glamorous from 'glamorous';

import PictureUpload from './PictureUpload';
import Review from './Review';
import ReviewHeader from './ReviewHeader';
import ReviewFooter from '../reviews/ReviewFooter';
import { applyOnMobile } from '../../styles/mediaQueries';
import {
    xsmallDistance,
    smallDistance,
    mediumDistance,
} from '../../styles/distances';
import colors from '../../styles/colors';
import { pxToRem } from '../../styles/unitConverter';
import PicturePreview from './PicturePreview';

const {
    reviewContainerBackgroundColor,
    reviewContainerBorderColor,
    reviewHeaderBackgroundColor,
} = colors;

const pictureUploadContainerWidth = 190;
const pictureUploadContainerHeight = 140;
const pictureUploadContainerMobileHeight = 80;
const pictureContainerMobileHeight = 2 * pictureUploadContainerMobileHeight;

const StyledContainer = glamorous.div({
    backgroundColor: reviewContainerBackgroundColor,
    marginTop: smallDistance,

    borderBottomWidth: '2px',
    borderBottomColor: reviewContainerBorderColor,
    borderBottomStyle: 'solid',
});

const StyledThumb = glamorous.div(({ hasPictures }) => ({
    position: 'relative',
    width: pxToRem(pictureUploadContainerWidth),
    height: pxToRem(pictureUploadContainerHeight),
    float: 'left',
    overflow: 'hidden',

    [applyOnMobile]: {
        float: 'none',
        width: '100%',
        height: pxToRem(
            hasPictures
                ? pictureContainerMobileHeight
                : pictureUploadContainerMobileHeight,
        ),
        top: '7rem',
    },
}));

const TextContainer = glamorous.div({
    float: 'right',
    width: `calc( 100% - ${pictureUploadContainerWidth}px - ${mediumDistance})`,
    textAlign: 'left',

    [applyOnMobile]: {
        float: 'none',
        width: '100%',
    },
});

const ReviewItemHeaderContainer = glamorous.div({
    backgroundColor: reviewHeaderBackgroundColor,

    paddingTop: xsmallDistance,
    paddingLeft: mediumDistance,
    paddingRight: mediumDistance,

    [applyOnMobile]: {
        paddingBottom: smallDistance,
    },
});

const ReviewItemBodyContainer = glamorous.div(({ status }) => ({
    padding: `${smallDistance} ${mediumDistance} ${mediumDistance}`,
    opacity: status !== 'PUBLISHED' ? 0.5 : 1,

    [applyOnMobile]: {
        paddingBottom: smallDistance,
    },
}));

export default function ({ review }) {
    const {
        hotel: { id: hotelId },
        pictures,
        status,
    } = review;
    const totalPictures = pictures.length;
    const hasPictures = Boolean(totalPictures);
    const thumb = hasPictures
        ? h(PicturePreview, {
              picture: pictures[0],
              totalPictures,
              review,
          })
        : h(PictureUpload, { hotelId });

    return h(StyledContainer, [
        h(ReviewItemHeaderContainer, [h(ReviewHeader, review.hotel)]),
        h(ReviewItemBodyContainer, { status }, [
            h(StyledThumb, { hasPictures }, [thumb]),
            h(TextContainer, [h(Review, { review, hasPictures })]),
        ]),
        h(ReviewFooter, review),
    ]);
}
