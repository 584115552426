import h from 'react-hyperscript';
import options from '@designsystem/options';
import glamorous from 'glamorous';
import { useEffect } from 'react';

import { sizes, variations, WaveButton } from '../../WaveButton';
import { pxToRem } from '../../../styles/unitConverter';
import RetainMembership from './RetainMembership';
import { useCancellationContext } from '../context/CancellationContext';
import { waveFontL, waveHeadline2XSSlim } from '../../../styles/waveHeadlines';

const Title = glamorous.div({ ...waveHeadline2XSSlim });

const AdditionalInfoLabel = glamorous.label({
    color: options.colors.gray20,
    fontWeight: options.fontWeights.mediumBold,
});

const AdditionalInfoTextArea = glamorous.textarea({
    display: 'block',
    borderRadius: options.radii.small,
    borderColor: options.colors.gray,
    width: '100%',
    marginTop: options.space.xs,
});
AdditionalInfoTextArea.displayName = 'AdditionalInfoTextArea';

const TextContainer = glamorous.div({
    ...waveFontL,
    marginBottom: options.space.xl,
});

function CancellationRetainOrReject() {
    const {
        isSendingCancellationRequest,
        setCancellationComment,
        trackEventForPremium,
        getCancellationReason,
    } = useCancellationContext();
    useEffect(() => {
        trackEventForPremium({
            event: {
                eventCategory: 'Premium-Club',
                eventAction: 'Cancellation-Step2-Shown',
                eventLabel: getCancellationReason(),
            },
        });
    }, []);

    return h('div', [
        h(RetainMembership),
        h(Title, 'Du möchtest dennoch kündigen?'),
        h(
            TextContainer,
            'Wir konnten Dich nicht überzeugen und Du möchtest auf die Vorteile verzichten? Dann kündige Deine Mitgliedschaft hier.',
        ),
        h(
            AdditionalInfoLabel,
            'Wie hätten wir Deine Kündigung verhindern können?',
        ),
        h(AdditionalInfoTextArea, {
            rows: 4,
            maxLength: 255,
            onChange: (e) => setCancellationComment(e.target.value),
        }),
        h(
            WaveButton,
            {
                variant: variations.PRIMARY,
                size: sizes.S,
                onClick: (e) => e.currentTarget.blur(),
                type: 'submit',
                loading: isSendingCancellationRequest,
                customStyles: {
                    margin: `${pxToRem(options.space['3xl'])} auto 0 auto`,
                    display: 'block',
                },
            },
            'Mitgliedschaft kündigen',
        ),
    ]);
}

export default CancellationRetainOrReject;
