const CANCELLATION_INSURANCE = 'CANCELLATION_INSURANCE';
export const HEALTH_INSURANCE = 'HEALTH_INSURANCE';
export const PREMIUM_DISCOUNT = 'PREMIUM_DISCOUNT';
const PACKAGE_TRANSFER = 'PACKAGE_TRANSFER';
const RAIL_AND_FLY = 'RAIL_AND_FLY';

const isServiceOfType = (type, service) => service.type === type;
const isCancellationInsurance = (service) =>
    isServiceOfType(CANCELLATION_INSURANCE, service);
const isHealthInsurance = (service) =>
    isServiceOfType(HEALTH_INSURANCE, service);
const isInsuranceService = (service) =>
    isCancellationInsurance(service) || isHealthInsurance(service);

const isPremiumService = (service) =>
    isHealthInsurance(service) || isServiceOfType(PREMIUM_DISCOUNT, service);
const isRailAndFlyIncluded = (service) =>
    isServiceOfType(RAIL_AND_FLY, service) &&
    service.detail.status === 'INCLUDED';
const isNotWished = ({ detail }) =>
    detail && detail.status && detail.status === 'NOT_WISHED';

const findRailAndFlyIncluded = (services) =>
    services.find(isRailAndFlyIncluded);
const findHotelService = (services) =>
    services.find(isServiceOfType.bind(null, 'HOTEL'));
const findFlightService = (services) =>
    services.find(isServiceOfType.bind(null, 'FLIGHT'));

const findIdFromHotelService = (services) => {
    const hotel = findHotelService(services);
    return (hotel && hotel.detail && hotel.detail.id) || null;
};
const findPackageTransfer = (services) =>
    services.find((service) => isServiceOfType(PACKAGE_TRANSFER, service));

export {
    isCancellationInsurance,
    isHealthInsurance,
    isInsuranceService,
    isPremiumService,
    isRailAndFlyIncluded,
    isNotWished,
    findRailAndFlyIncluded,
    findHotelService,
    findFlightService,
    findIdFromHotelService,
    findPackageTransfer,
};
