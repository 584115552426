import h from 'react-hyperscript';
import moment from 'moment/moment';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveFontM, waveHeadline2XS } from '../../../../styles/waveHeadlines';
import {
    waveMdOnlyMediaQuery,
    waveSmMediaQuery,
} from '../../../../styles/waveMediaQueries';
import { waveTextM } from '../../../../styles/waveText';

const Headline = glamorous.div({
    ...waveHeadline2XS,
    marginBottom: options.space.m,
});

const RentalcarCardContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    margin: `${options.space.xl}px ${options.space.l}px`,
    [waveMdOnlyMediaQuery]: {
        margin: `${options.space.xl}px ${options.space.m}px`,
    },
});

const CarBookingContainer = glamorous.div({});

const CarBookingInformationContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: options.space.xs,
});

const CarBookingInformationContainerImage = glamorous.img({
    width: '60px',
    height: '60px',
    margin: '0 2px',
    objectFit: 'contain',
    transform: 'rotateY(180deg)',
});

const CarBookingStatus = glamorous.span({
    ...waveTextM,
    color: options.colors.green40,
    fontWeight: options.fontWeights.mediumBold,
});

const TripContainer = glamorous.div({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [waveSmMediaQuery]: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});

const TripInfo = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
});

const Destination = glamorous.span({
    ...waveFontM,
    fontWeight: options.fontWeights.bold,
});

const DateText = glamorous.span({
    fontSize: `${options.fontSizes.xs}px`,
    lineHeight: '18px',
    fontWeight: options.fontWeights.normal,
});

const buildDestination = (destination, transferType) => {
    if (!transferType) {
        return destination;
    }

    return `${destination} • ${transferType === 'InsideTerminal' ? 'im Terminal' : 'per Shuttle'}`;
};

const Button = glamorous.a([
    {
        ...waveTextM,
        color: options.colors.link,
        borderColor: options.colors.link,
        textDecoration: 'none',
        marginTop: options.space.l,
        height: options.space['3xl'],
        width: 217,
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: options.radii.full,
        cursor: 'pointer',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        fontWeight: 'bold',
        justifyContent: 'center',
        textTransform: 'uppercase',
        transition: 'all 0.3s ease',
        background: 'transparent',
        '&:not(:disabled):hover': {
            background: options.colors.white,
            borderColor: options.colors.blue20,
            color: options.colors.blue20,
        },
        '&:focus': {
            color: options.colors.blue,
            outlineOffset: 'unset',
        },
    },
]);

export function RentalcarBookingCard({
    destinationName,
    pickupDatetime,
    dropoffDatetime,
    carOfferDestinationInfo,
    carOfferPictureUrl,
    transferType,
    status,
}) {
    return h(RentalcarCardContainer, [
        h(
            Headline,
            `Mein Mietwagen ${carOfferDestinationInfo.preposition} ${carOfferDestinationInfo.destination}`,
        ),
        h(CarBookingContainer, [
            h(CarBookingInformationContainer, [
                h(CarBookingInformationContainerImage, {
                    src: carOfferPictureUrl,
                }),

                h(TripContainer, [
                    h(TripInfo, [
                        h(
                            Destination,
                            buildDestination(destinationName, transferType),
                        ),
                        h(
                            DateText,
                            `${moment(pickupDatetime).format('DD.MM.YYYY')} - ${moment(dropoffDatetime).format('DD.MM.YYYY')}`,
                        ),
                    ]),
                    status === 'Confirmed' &&
                        h(
                            CarBookingStatus,
                            { className: 'rentalcar-booking-status' },
                            'Bestätigt',
                        ),
                ]),
            ]),
            h(
                Button,
                {
                    href: '/mhc/mein-mietwagen/buchungen',
                },
                'Mietwagendetails',
            ),
        ]),
    ]);
}
