import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import { pxToRem } from '../../styles/unitConverter';
import { premiumCancellation } from '../../mutators/premium/premiumCancellation';
import CancellationConfirmation from './cancellation/CancellationConfirmation';
import { noticeError } from '../../lib/newrelic';
import {
    waveSmOnlyMediaQuery,
    waveXsOnlyMediaQuery,
} from '../../styles/waveMediaQueries';
import CancellationReasons from './cancellation/CancellationReasons';
import CancellationRetainOrReject from './cancellation/CancellationRetainOrReject';
import { CancellationContext } from './context/CancellationContext';

const FormContainer = glamorous.div({
    [waveXsOnlyMediaQuery]: {
        marginTop: options.space['3xl'],
    },
    [waveSmOnlyMediaQuery]: {
        marginTop: options.space['6xl'],
    },
    boxShadow: options.shadows.raised,
    background: options.colors.white,
    marginTop: options.space['7xl'],
    padding: options.space['3xl'],
    borderRadius: options.radii.small,
});

const Form = glamorous.form({});
Form.displayName = 'Form';

const LinkToCancellationForm = glamorous.a({
    textDecoration: 'none',
    color: options.colors.blue,
});

const InformationParagraph = glamorous.div({
    fontFamily: options.fonts.body,
    fontSize: pxToRem(options.fontSizes.s),
});

const BackToYourPremiumPage = glamorous.a({
    display: 'block',
    textTransform: 'uppercase',
    textDecoration: 'none',
    textAlign: 'center',
    margin: `${options.space['2xl']}px auto ${options.space['7xl']}px auto`,
    fontWeight: options.fontWeights.bold,
    color: options.colors.blue,
});

const FormSteps = {
    REASON: 'REASON',
    RETAIN_OR_REJECT: 'RETAIN_OR_REJECT',
};
function CancellationForm({ periodEndDate, trackEventForPremium }) {
    const [currentFormStep, setFormStep] = useState(FormSteps.REASON);
    const [selectedCancellationReason, setSelectedCancellationReason] =
        useState(null);
    const [cancellationComment, setCancellationComment] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [sendCancellation, { data: cancellationResponse, loading, error }] =
        useMutation(premiumCancellation);

    if (error) {
        noticeError(error);

        trackEventForPremium({
            event: {
                eventCategory: 'Premium-Club',
                eventAction: 'Cancellation-Error-Shown',
            },
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();

        sendCancellation({
            variables: {
                input: {
                    reason: selectedCancellationReason,
                    comment: cancellationComment,
                },
            },
            onCompleted: () => {
                setIsSubmitted(true);
            },
        });

        trackEventForPremium({
            event: {
                eventCategory: 'Premium-Club',
                eventAction: 'Click-Submit-Cancellation',
                eventLabel: selectedCancellationReason,
            },
        });
    };

    return h(Fragment, [
        !isSubmitted &&
            h(Fragment, [
                h(InformationParagraph, [
                    h(
                        'p',
                        {
                            style: {
                                fontWeight: options.fontWeights.bold,
                            },
                        },
                        'Schade, dass Du HolidayCheck Premium verlassen möchtest.',
                    ),
                    h('p', [
                        `Hier kannst Du Deine Mitgliedschaft ordentlich zum Ende des Mitgliedsjahres am ${moment(periodEndDate).format('DD.MM.YYYY')} kündigen. Danach verfällt Dein Reiseguthaben. Unter bestimmten Vorraussetzungen kannst Du auch vorzeitig außerordentlich kündigen. Wenn Du glaubst, Gründe für eine vorzeitige Kündigung zu haben, `,
                        h(
                            LinkToCancellationForm,
                            {
                                href: '/premium/kuendigung',
                                onClick: () =>
                                    trackEventForPremium({
                                        event: {
                                            eventCategory: 'Premium-Club',
                                            eventAction:
                                                'Click-Extraordinary-Cancellation',
                                            eventLabel: 'Cancellation-Page',
                                        },
                                    }),
                            },
                            'teile uns dies bitte hier mit.',
                        ),
                    ]),
                ]),
                h(
                    CancellationContext.Provider,
                    {
                        value: {
                            setCancellationComment,
                            setSelectedCancellationReason,
                            getCancellationReason: () =>
                                selectedCancellationReason,
                            onReasonSubmit: () => {
                                setFormStep(FormSteps.RETAIN_OR_REJECT);

                                trackEventForPremium({
                                    event: {
                                        eventCategory: 'Premium-Club',
                                        eventAction: 'Click-Go-To-Cancellation',
                                        eventLabel: selectedCancellationReason,
                                    },
                                });
                            },
                            currentFormStep,
                            isSendingCancellationRequest: loading,
                            trackEventForPremium,
                        },
                    },
                    [
                        h(FormContainer, [
                            h(Form, { onSubmit }, [
                                currentFormStep === FormSteps.REASON &&
                                    h(CancellationReasons),
                                currentFormStep ===
                                    FormSteps.RETAIN_OR_REJECT &&
                                    h(CancellationRetainOrReject),
                            ]),
                        ]),
                    ],
                ),
                h(
                    BackToYourPremiumPage,
                    {
                        href: '/mhc/mein-premium',
                        onClick: () =>
                            trackEventForPremium({
                                event: {
                                    eventCategory: 'Premium-Club',
                                    eventAction: 'Click-Back-To-Mein-Premium',
                                    eventLabel: 'Cancellation-Page',
                                },
                            }),
                    },
                    'Zurück zu deinem Premium-Bereich',
                ),
            ]),
        h(CancellationConfirmation, { isSubmitted, cancellationResponse }),
    ]);
}

export default CancellationForm;
