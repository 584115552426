import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';

import trackEvent from '../../../lib/trackEvent';
import { RentalcarButton } from './utils/RentalcarButton';

const NoBookingsContainer = glamorous.div({
    textAlign: 'center',
    marginTop: options.space['7xl'],
    marginBottom: options.space['7xl'],
    '& img': {
        marginBottom: options.space.m,
    },
    '& div': {
        color: options.colors.gray40,
        fontWeight: 'bold',
        width: 252,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

const Img = glamorous.img({
    transform: 'rotateY(180deg)',
});

const MietwagenBookingEmpty = function ({ config, message, children }) {
    const utmParameters = '?utm_source=mHC&utm_medium=Hub&utm_campaign=CarNo';

    return h(NoBookingsContainer, {}, [
        h(Img, { src: `${config.assetsPath}/hcMietwagen/car.svg` }),
        h('div', {}, [
            h('div', message || 'Aktuell hast Du keinen gebuchten Mietwagen. '),
            children,
            h(
                RentalcarButton,
                {
                    href: `/mietwagen${utmParameters}`,
                    onClick: () =>
                        trackEvent({
                            event: 'event',
                            eventCategory: 'mHCHub',
                            eventAction: 'gotorentalcar',
                            eventLabel: 'Mietwagen Buchen',
                        }),
                },
                'Jetzt Mietwagen buchen',
            ),
        ]),
    ]);
};

export default MietwagenBookingEmpty;
