import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    isActiveBooking,
    hasStarted,
} from '../../../../common/lib/bookingHelper';
import BookingSectionHeadline from '../BookingSectionHeadline';
import Link from '../../navigation/Link';
import RightArrowLine from '../../icons/RightArrowLine';
import { pxToRem } from '../../../styles/unitConverter';
import { blue } from '../../../styles/waveColors';
import { secondaryFont } from '../../../styles/fonts';
import useBookingContext from '../lib/useBookingContext';

const { UITextL, PTSansFamily } = secondaryFont;

const TOUROPERATOR_BOOKING = '35d56e6b-187f-3564-95a8-a6d5270f2cb5';
const TOUROPERATOR_EAN = '87281d5b-2f43-38d9-99b2-3c83e4e031dd';

const Instruction = glamorous.span(UITextL, PTSansFamily);
Instruction.displayName = 'Instruction';

const CancellationFAQLink = glamorous(Link)();

const CancelLink = glamorous(Link)({
    fontWeight: 'bold',
    color: blue,
    marginTop: pxToRem(24),
    display: 'block',
    textTransform: 'uppercase',
    lineHeight: 'inherit',

    '> svg': {
        verticalAlign: 'middle',
        marginBottom: '2px',
        marginLeft: pxToRem(11),
    },
});
CancelLink.displayName = 'CancelLink';

const externalSelfserviceTourOperators = [
    TOUROPERATOR_BOOKING,
    TOUROPERATOR_EAN,
];

const isABookingWithBookingOrEAN = (tourOperatorId) =>
    externalSelfserviceTourOperators.includes(tourOperatorId);

const getEventLabelForExternalSelfservice = (tourOperatorId) => {
    switch (tourOperatorId) {
        case TOUROPERATOR_BOOKING:
            return 'booking';
        case TOUROPERATOR_EAN:
            return 'ean';
        default:
            return 'other';
    }
};

const FTI_GROUP_UUIDS = [
    '67a26920-392a-31ee-bb2d-370366631b40', // FTI
    '95df45ba-ac8f-3c8b-89b9-a46f928ea06a', // XFTI
    '47c4ccbe-bee7-30b0-95a9-0006b1b26627', // 5VF
    '23fad1f5-36c8-3362-a5fb-1ab0ff5dd47f', // X5VF
    '527972b3-972e-3cd1-8b5c-3ad181968e7f', // BIG
    'ae8247aa-ed10-3daf-955d-a866cecd27ac', // XBIG
];

const tourOperatorBelongsToFtiGroup = ({ id }) => {
    return FTI_GROUP_UUIDS.includes(id);
};

const getCancellationConfig = (booking) => {
    const { externalSelfserviceUrl, id, tourOperator } = booking;

    if (tourOperatorBelongsToFtiGroup(tourOperator)) {
        return {
            link: 'https://www.holidaycheck.de/aktuelle-reisehinweise/fti',
            target: '_self',
            instruction:
                'Alle Informationen zu der Insolvenz von FTI findest du hier:',
            text: 'Zur Insolvenz Seite',
            eventLabel: 'hc',
        };
    }

    if (!externalSelfserviceUrl) {
        return {
            link: `/meinReisenavigator.html?action=cancellation&bookingUuId=${id}`,
            target: '_self',
            instruction:
                'Bevor Sie Ihre Reise stornieren, ermitteln wir für Sie mögliche Kosten nach ' +
                'den AGB Ihres Reiseveranstalters. In einem zweiten Schritt können Sie direkt stornieren.',
            text: 'Zur Stornierung',
            eventLabel: 'hc',
        };
    }

    return {
        link: externalSelfserviceUrl,
        target: '_blank',
        instruction:
            'Falls Du stornieren möchtest, klicke einfach auf den nachfolgenden Link. ' +
            'Auf der anschließenden Seite kannst Du mit einem Klick Deine Reise stornieren.',
        text: 'Zur Stornierung',
        eventLabel: getEventLabelForExternalSelfservice(tourOperator.id),
    };
};

const renderAlreadyCancelledSection = (myRef) => {
    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, 'Stornierungsstatus'),
        h(
            Instruction,
            'Wir haben Deine Stornoanfrage erhalten und kümmern uns darum! ' +
                'Du bekommst eine Rückmeldung über den Status und den weiteren Ablauf sobald ' +
                'wir eine Rückmeldung vom Reiseveranstalter erhalten.',
        ),
    ]);
};

const renderCancellationSection = (trackEventWithBooking, booking, myRef) => {
    const { link, target, instruction, text, eventLabel } =
        getCancellationConfig(booking);

    const cancellationFAQLink =
        '/hilfe/category/buchung/stornierung-ich-habe-eine-frage-zum-thema-stornierung';

    const isFtiGroup = tourOperatorBelongsToFtiGroup(booking.tourOperator);

    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, 'Urlaub stornieren'),
        isFtiGroup && h(Instruction, [h('span', `${instruction}`)]),
        !isFtiGroup &&
            h(Instruction, [
                h('span', `${instruction} Bei Unsicherheiten prüfen Sie `),
                h(
                    CancellationFAQLink,
                    {
                        href: cancellationFAQLink,
                        target: '_blank',
                    },
                    'häufige Fragen zur Stornierung.',
                ),
            ]),
        h(
            CancelLink,
            {
                href: link,
                target,
                onClick: trackEventWithBooking.bind(null, {
                    event: 'event',
                    eventCategory: 'mybooking',
                    eventAction: 'cancellationLink',
                    eventLabel,
                }),
            },
            [h('span', text), h(RightArrowLine)],
        ),
    ]);
};

const isDisabled = ({ bookingType, externalSelfserviceUrl, tourOperator }) => {
    return (
        !isActiveBooking(bookingType) ||
        (isABookingWithBookingOrEAN(tourOperator.id) && !externalSelfserviceUrl)
    );
};

const getCancellationRequestedIds = (localStorage) => {
    const CANCELLATIONS_REQUESTED_KEY = 'cancellationsRequested';
    const cancellationsRequestedFromLocalStorage = localStorage.getItem(
        CANCELLATIONS_REQUESTED_KEY,
    );
    const parsedCancellationRequested = JSON.parse(
        cancellationsRequestedFromLocalStorage,
    );

    if (
        parsedCancellationRequested &&
        Array.isArray(parsedCancellationRequested)
    ) {
        return parsedCancellationRequested;
    }

    return [];
};

const isCancellationRequested = (localStorage, booking) => {
    const cancellationRequestedIds = getCancellationRequestedIds(localStorage);
    return cancellationRequestedIds.includes(booking.id);
};

const Cancellation = (
    { booking, myRef, setWillRender },
    { localStorage, getCurrentDate },
) => {
    if (isDisabled(booking) || hasStarted(booking, getCurrentDate())) {
        return null;
    }
    const { trackEventWithBooking } = useBookingContext();
    useEffect(() => setWillRender(true), []);

    if (isCancellationRequested(localStorage, booking)) {
        trackEventWithBooking({
            event: 'event',
            eventCategory: 'mybooking',
            eventAction: 'cancellation-request-sent',
        });

        return renderAlreadyCancelledSection(myRef);
    }

    return renderCancellationSection(trackEventWithBooking, booking, myRef);
};

Cancellation.propTypes = {
    booking: PropTypes.shape({
        id: PropTypes.string.isRequired,
        tourOperator: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
        externalSelfserviceUrl: PropTypes.string,
    }).isRequired,
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

Cancellation.contextTypes = {
    localStorage: PropTypes.object.isRequired,
    getCurrentDate: PropTypes.func.isRequired,
};

Cancellation.displayName = 'Cancellation';

export default Cancellation;
