import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveTextM } from '../../../../styles/waveText';

export const RentalcarButton = glamorous.a([
    {
        ...waveTextM,
        color: options.colors.link,
        borderColor: options.colors.link,
        textDecoration: 'none',
        marginTop: options.space.l,
        height: options.space['3xl'],
        width: 217,
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: options.radii.full,
        cursor: 'pointer',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        fontWeight: 'bold',
        justifyContent: 'center',
        textTransform: 'uppercase',
        transition: 'all 0.3s ease',
        background: 'transparent',
        '&:not(:disabled):hover': {
            background: options.colors.white,
            borderColor: options.colors.blue20,
            color: options.colors.blue20,
        },
        '&:focus': {
            color: options.colors.blue,
            outlineOffset: 'unset',
        },
    },
]);
