import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { pxToRem } from '../../styles/unitConverter';
import { waveTextM } from '../../styles/waveText';
import { gray90 } from '../../styles/waveColors';
import { isHealthInsurance } from './lib/serviceHelpers';
import Hint from '../myPremium/Hint';
import { sizes, variations, WaveButton } from '../WaveButton';
import { postTakenBenefits } from '../../mutators/takenBenefits';
import { noticeError } from '../../lib/newrelic';
import useBookingContext from './lib/useBookingContext';

const DAYS_BEFORE_ACTIVATION = 10;

const PremiumHealthInsuranceContainer = glamorous.div(
    ({ customStyles = {} }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: options.space['2xs'],
        backgroundColor: options.colors.purple100,
        padding: pxToRem(options.space.m),
        borderTop: `1px solid ${gray90}`,
        ...customStyles,
    }),
);

const Text = glamorous.div(({ customStyles }) => ({
    paddingBottom: pxToRem(options.space.s),
    fontFamily: options.fonts.body,
    ...waveTextM,
    ...customStyles,
}));
Text.displayName = 'Text';

const Logo = glamorous.img({
    height: pxToRem(40),
});

const getEventLabel = (pathname, bookingId) => {
    if (pathname === `/mhc/meine-buchungen/${bookingId}`) {
        return 'Booking-detail-view';
    }

    if (pathname === '/mhc/meine-buchungen') {
        return 'Booking-list-view';
    }

    return '';
};

const alreadyIncludedText = [
    h('span', 'Diese Versicherung wurde '),
    h('b', 'für diese Buchung erfolgreich aktiviert. '),
    h(
        'span',
        'Die Unterlagen wurden von HanseMerkur direkt per E-Mail an Dich verschickt (bitte kontrolliere auch Deinen Spam-Ordner).',
    ),
];

const renderSuccessMessage = (
    { window, bookingId, hanseMerkurLogo },
    { trackEventWithBookingContext },
) => {
    trackEventWithBookingContext({
        eventCategory: 'Premium-Club',
        eventAction: 'Insurance-ordering-success',
        eventLabel: getEventLabel(window.location.pathname, bookingId),
    });

    return h(
        PremiumHealthInsuranceContainer,
        { customStyles: { flexDirection: 'row' } },
        [h(Text, { paddingBottom: 0 }, alreadyIncludedText), hanseMerkurLogo],
    );
};

const renderErrorMessage = (
    { error, window, bookingId },
    { trackEventWithBookingContext },
) => {
    if (error) {
        noticeError(error.message, {
            pageName: 'MyBookings',
            component: 'PremiumHealthInsuranceFooter',
        });
    }

    trackEventWithBookingContext({
        eventCategory: 'Premium-Club',
        eventAction: 'Insurance-ordering-error',
        eventLabel: getEventLabel(window.location.pathname, bookingId),
    });

    return h(Hint, {
        type: 'error',
        body: 'Leider ist bei der Aktivierung ein Fehler aufgetreten.Du brauchst nichts weiter zu tun. Ein Mitarbeiter wurde informiert und wir melden uns in den nächsten 3 Werktagen bei Dir.',
    });
};

const renderActivationTrigger = (
    { booking, loading, window },
    { fetchTakenBenefits, trackEventWithBookingContext },
    hanseMerkurLogo,
) => {
    const shouldEnableBookButton =
        moment(booking.startDate).diff(moment(), 'days', true) <=
        DAYS_BEFORE_ACTIVATION;
    const text = shouldEnableBookButton
        ? h(Text, [
              h(
                  'span',
                  'Diese Versicherung ist Teil Deiner HolidayCheck Premium-Mitgliedschaft und kann ',
              ),
              h('b', 'jetzt'),
              h('span', ' aktiviert werden.'),
          ])
        : h(
              Text,
              'Diese Versicherung ist Teil Deiner HolidayCheck Premium-Mitgliedschaft und kann 10 Tage vor Abreise kostenlos aktiviert werden.',
          );

    return h(
        PremiumHealthInsuranceContainer,
        { customStyles: { flexWrap: 'wrap' } },
        [
            text,
            h(
                WaveButton,
                {
                    variant: variations.PRIMARY,
                    size: sizes.S,
                    onClick: () => {
                        trackEventWithBookingContext({
                            eventCategory: 'Premium-Club',
                            eventAction: 'Click-Insurance-Activation',
                            eventLabel: getEventLabel(
                                window.location.pathname,
                                booking.id,
                            ),
                        });

                        fetchTakenBenefits({
                            variables: {
                                input: {
                                    takenBenefits: [
                                        { id: 'TRAVEL_HEALTH_INSURANCE' },
                                    ],
                                    bookingId: booking.id,
                                    midofficeId: booking.midofficeId,
                                },
                            },
                        });
                    },
                    type: 'submit',
                    disabled: !shouldEnableBookButton,
                    loading,
                    withArrow: true,
                    customStyles: {
                        border: 'none',
                        background:
                            'linear-gradient(90deg, #9624B2 0%, #50449C 100%)',
                        cursor: 'pointer',
                        '&:hover': {
                            background:
                                shouldEnableBookButton &&
                                'linear-gradient(90deg,#810694 0,#3c3088 100%)',
                        },
                    },
                },
                'Kostenlos aktivieren',
            ),
            hanseMerkurLogo,
        ],
    );
};

const PremiumHealthInsuranceFooter = (
    { booking },
    { config: { assetsPath }, window },
) => {
    const healthInsuranceService = booking.services.find(isHealthInsurance);
    const [fetchTakenBenefits, { data, loading, error }] =
        useMutation(postTakenBenefits);
    const hanseMerkurLogo = h(Logo, {
        src: `${assetsPath}/myBookings/hanse-merkur.svg`,
        alt: 'HanseMerkur',
    });
    const { trackEventWithBookingContext } = useBookingContext();

    if (
        healthInsuranceService &&
        healthInsuranceService.detail.processingStatus === 'INCLUDED'
    ) {
        return h(
            PremiumHealthInsuranceContainer,
            { customStyles: { flexWrap: 'wrap' } },
            [h(Text, alreadyIncludedText), hanseMerkurLogo],
        );
    }

    if (data && data.postTakenBenefits === 'SUCCESS') {
        return renderSuccessMessage(
            { window, bookingId: booking.id, hanseMerkurLogo },
            { trackEventWithBookingContext },
        );
    }

    if (error || (data && data.postTakenBenefits === 'ERROR')) {
        return renderErrorMessage(
            { error, window, bookingId: booking.id },
            { trackEventWithBookingContext },
        );
    }

    return renderActivationTrigger(
        { booking, loading, window },
        { fetchTakenBenefits, trackEventWithBookingContext },
        hanseMerkurLogo,
    );
};

PremiumHealthInsuranceFooter.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
    window: PropTypes.object.isRequired,
};

export default PremiumHealthInsuranceFooter;
