import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';

export default async function (context) {
    const query = stripIndent`
        query RootQuery {
          upcomingTravelForUser {
            booking {
              startDate
              endDate
            }
          }
        }
    `;

    const response = await graphqlFetch(context, { query });

    if (response.ok) {
        const {
            data: { upcomingTravelForUser },
        } = await response.json();
        return upcomingTravelForUser;
    }

    return null;
}
