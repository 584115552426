import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { oneLine } from 'common-tags';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import Grid from './grid/Grid';
import { bigHeadlineFont, headlineFont } from '../styles/fonts';
import Page from './Page';
import CategoriesQuery from './myBadges/CategoriesQuery';
import Link from './navigation/Link';
import LeftArrowLine from './icons/LeftArrowLine';
import { pxToRem } from '../styles/unitConverter';
import {
    xsmallDistance,
    smallDistance,
    mediumDistance,
} from '../styles/distances';
import { applyOnMobile, applyOnTabletAndUp } from '../styles/mediaQueries';
import UserPointsQuery from './myBadges/UserPointsQuery';

const introductionWidthTabletDesktop = pxToRem(600);
const Headline = glamorous.h1({
    ...bigHeadlineFont,
    ...{
        textAlign: 'center',
        margin: `${options.space.xl} 0`,
    },
});

const SubHeadline = glamorous.h2({
    ...headlineFont,
    ...{
        textAlign: 'center',
        marginTop: options.space['3xl'],
        marginBottom: options.space.l,
        [applyOnTabletAndUp]: {
            marginTop: options.space['6xl'],
        },
    },
});

const Introduction = glamorous.p({
    textAlign: 'center',
    margin: 'auto',
    marginBottom: options.space.l,

    [applyOnTabletAndUp]: {
        width: introductionWidthTabletDesktop,
    },
    [applyOnMobile]: {
        padding: `0 ${xsmallDistance}`,
    },
});

const HeadIllustration = glamorous.img({
    width: '100%',
    maxWidth: pxToRem(226),
    margin: `${mediumDistance} auto ${smallDistance}`,
    padding: '0 auto',
    display: 'block',
});

function MyBadges(props, { config: { assetsPath } }) {
    return h(
        Page,
        {
            pagename: '/mhc/badges',
            title: 'Auszeichnungen & Urlaubssterne',
        },
        [
            h(Grid, [
                h(Link, { href: '/mhc/mein-holidaycheck' }, [
                    h(LeftArrowLine, {
                        css: {
                            paddingLeft: mediumDistance,
                            paddingRight: pxToRem(5),
                            paddingBottom: pxToRem(2),
                        },
                    }),
                    'Zur Übersicht',
                ]),
                h(HeadIllustration, {
                    src: `${assetsPath}/myHolidayCheck/illustration-wallet.svg`,
                    alt: 'Auszeichnungen',
                }),
                h(Headline, 'Auszeichnungen & Urlaubssterne'),
                h(SubHeadline, 'Urlaubssterne'),
                h(
                    Introduction,
                    oneLine`Ihre Beiträge helfen anderen Urlaubern. Mit jedem Beitrag sammeln Sie Punkte und kommen dem nächsten Urlaubsstern näher.`,
                ),
                h(UserPointsQuery, {
                    style: { marginTop: options.space.xl },
                }),
                h(SubHeadline, 'Auszeichnungen'),
                h(
                    Introduction,
                    oneLine`Andere Urlauber sehen mit diesen
            Auszeichnungen auf einen Blick, wie Sie sich für die Urlauber-Community engagieren.
            Je vielfältiger Ihre Beiträge sind, desto mehr Auszeichnungen können Sie sammeln.`,
                ),
                h(CategoriesQuery),
            ]),
        ],
    );
}

MyBadges.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default MyBadges;
