import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import moment from 'moment';
import { useQuery } from '@apollo/client';

import Page from '../Page';
import Title from './Title';
import MembershipContact from './MembershipContact';
import MembershipInformation from './MembershipInformation';
import { waveXsAndSmOnlyMediaQuery } from '../../styles/waveMediaQueries';
import CancellationForm from './CancellationForm';
import Hint from './Hint';
import { premiumMembership } from '../../queries/premiumMembership';
import {
    extractActiveContract,
    extractActivePeriod,
} from '../../lib/premium/membership';
import TransitionScreen from '../navigation/TransitionScreen';

const MyPremiumContainer = glamorous.div({
    fontFamily: options.fonts.default,
});

const CancellationContainer = glamorous.div({
    [waveXsAndSmOnlyMediaQuery]: {
        padding: `0 ${options.space.xl}px`,
    },
    padding: `0 ${options.space['3xl']}px`,
    maxWidth: '792px',
    margin: '0 auto',
});

const CancellationPageContainer = ({ children, assetsPath }) =>
    h(MyPremiumContainer, [
        h(
            Page,
            {
                pagename: '/mhc/mein-premium/kuendigen',
                title: 'Mitgliedschaft kündigen',
            },
            [
                h(Title, {
                    config: { assetsPath },
                    subtitle: 'Mitgliedschaft kündigen',
                }),
                h(CancellationContainer, [children]),
            ],
        ),
    ]);

const NoActiveSubscriptionHint = ({ trackEventForPremium }) =>
    h(Hint, {
        type: 'warning',
        header: 'Du scheinst derzeit kein Premium-Mitglied zu sein',
        body: [
            'Wenn Du glaubst eine Mitgliedschaft mit einer anderen E-Mail-Adresse angelegt zu haben, nutze bitte ',
            h(
                'a',
                {
                    href: '/premium/kuendigung',
                    onClick: () =>
                        trackEventForPremium({
                            event: {
                                eventCategory: 'Premium-Club',
                                eventAction: 'Click-Extraordinary-Cancellation',
                                eventLabel: 'Cancellation-Page: Not-Member',
                            },
                        }),
                },
                'folgendes Formular.',
            ),
            ' Wir melden uns dann bei dir.',
        ],
    });
NoActiveSubscriptionHint.displayName = 'NoActiveSubscriptionHint';

// eslint-disable-next-line max-statements
function PremiumCancellationPage(
    args,
    { config: { assetsPath }, trackEvent, window },
) {
    const { loading, error, data } = useQuery(premiumMembership);

    if (loading) {
        return h(TransitionScreen);
    }

    if (error) {
        return h(
            CancellationPageContainer,
            {
                assetsPath,
            },
            [
                h(Hint, {
                    type: 'error',
                    header: 'Es gab ein Problem bei der automatischen Kündigung',
                    body: 'Bitte versuche es in einigen Minuten erneut. Sollte das Probleme bestehen bleiben, kontaktiere bitte den Kundenservice.',
                }),
            ],
        );
    }

    const activeContract = extractActiveContract(
        data.premiumMembership.subscriptionContracts,
    );

    const trackEventForPremium = ({ event }) => {
        if (window.hasConsentedToTrackEvents) {
            trackEvent({
                event: 'event',
                eventCategory: 'Premium-Club',
                ...event,
                ...(data && data.premiumMembership.personAccountDetail.mhcUUID
                    ? {
                          mhcUUID:
                              data.premiumMembership.personAccountDetail
                                  .mhcUUID,
                      }
                    : {}),
            });
        }
    };

    if (!activeContract) {
        trackEventForPremium({
            event: {
                eventCategory: 'Premium-Club',
                eventAction: 'Cancellation-Not-Member-Shown',
            },
        });
        return h(
            CancellationPageContainer,
            {
                assetsPath,
            },
            [h(NoActiveSubscriptionHint, { trackEventForPremium })],
        );
    }

    const activePeriod = extractActivePeriod(
        activeContract.subscriptionPeriods,
    );
    const hasPendingTermination = activeContract.hasPendingTermination;

    if (hasPendingTermination) {
        trackEventForPremium({
            event: {
                eventCategory: 'Premium-Club',
                eventAction: 'Already-Cancelled-Shown',
            },
        });
    }

    return h(MyPremiumContainer, [
        h(
            Page,
            {
                pagename: '/mhc/mein-premium/kuendigen',
                title: 'Mitgliedschaft kündigen',
            },
            [
                h(Title, {
                    config: { assetsPath },
                    subtitle: 'Mitgliedschaft kündigen',
                }),
                h(CancellationContainer, [
                    activeContract &&
                        h(MembershipInformation, {
                            activeContract,
                        }),
                    hasPendingTermination &&
                        h(Hint, {
                            type: 'warning',
                            header: 'Du hast Deine Premium-Mitgliedschaft bereits gekündigt',
                            body: [
                                `Deine Mitgliedschaft wurde ordentlich zum ${moment(activeContract.revocationTerminationRequestDate).format('DD.MM.YYYY')} gekündigt. Unter bestimmten Vorraussetzungen kannst Du auch vorzeitig, d.h. `,
                                h(
                                    'a',
                                    {
                                        href: '/premium/kuendigung',
                                        onClick: () =>
                                            trackEventForPremium({
                                                event: {
                                                    eventCategory:
                                                        'Premium-Club',
                                                    eventAction:
                                                        'Click-Extraordinary-Cancellation',
                                                    eventLabel:
                                                        'Cancellation-Page: Already-Cancelled',
                                                },
                                            }),
                                    },
                                    'außerordentlich kündigen/widerrufen.',
                                ),
                            ],
                        }),
                    activeContract &&
                        !hasPendingTermination &&
                        h(CancellationForm, {
                            periodEndDate: activePeriod.periodEndDate,
                            trackEventForPremium,
                        }),
                ]),
                h(MembershipContact, { isCancellationPage: true }),
            ],
        ),
    ]);
}

PremiumCancellationPage.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
    trackEvent: PropTypes.func.isRequired,
    window: PropTypes.object.isRequired,
};

export default PremiumCancellationPage;
