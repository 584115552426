import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Checkmark(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M13.862 18.392L8.212 13.73a1.869 1.869 0 00-2.591 .225l-1.187 1.391a1.782 1.782
        0 00.224 2.548l8.478 6.994a1.854 1.854 0 001.338 .412 1.842 1.842 0 001.257
        -.642L27.57 10.786a1.8 1.8 0 00-.223 -2.554L25.933 7.065a1.86 1.86 0 00-2.598
        .227l-9.473 11.1z`,
        }),
    ]);
}
