import h from 'react-hyperscript';
import PropTypes from 'prop-types';

import { RentalcarBookingCard } from './RentalcarBookingCard';
import { RentalcarLinkCard } from './RentalcarLinkCard';
import { useFetchRentalCarBooking } from './useFetchRentalCarBooking';

const getUtmParameters = (hasHcTravel, hasMietwagen) => {
    const campaign = `Travel${hasHcTravel ? 'Yes' : 'No'}Car${hasMietwagen ? 'Yes' : 'No'}`;
    return `?utm_source=mHC&utm_medium=Hub&utm_campaign=${campaign}`;
};

/*
 * The card may display an active or upcoming rental car booking, prioritizing bookings
 * that are the same time as a recent HC Booking. If no related booking exists,
 * the next upcoming booking will be shown.
 * If no booking exists or all bookings are in the past, we will show a card that
 * links to the index page of HolidayCheck - Mietwagen.
 *
 * */
const RentalcarCard = (props, context) => {
    const { trackEvent } = context;
    const { bookings, hasHcTravel } = useFetchRentalCarBooking(context);

    const currentBooking = bookings[0] || null;
    const parameters = getUtmParameters(hasHcTravel, currentBooking !== null);

    return currentBooking !== null
        ? h(RentalcarBookingCard, {
              id: currentBooking.id,
              status: currentBooking.status,
              destinationName: currentBooking.carOfferDestination,
              pickupDatetime: currentBooking.pickupDatetime,
              dropoffDatetime: currentBooking.dropoffDatetime,
              carOfferDestinationInfo: currentBooking.carOfferDestinationRegion,
              carOfferPictureUrl: currentBooking.carOfferPictureUrl,
              transferType: currentBooking.transferType,
          })
        : h(RentalcarLinkCard, {
              bubbleImageLink: `${context.config.assetsPath}/hcMietwagen/CarBubble.png`,
              carImageLink:
                  '/mietwagen/assets-image/180x115/f080a9f0-f3c9-433a-bc27-8180dcd6e561.png',
              trackEvent,
              parameters,
          });
};

export default RentalcarCard;

RentalcarCard.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
    getCurrentDate: PropTypes.func,
};
