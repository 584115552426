import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';
import h from 'react-hyperscript';

import AuthenticatedRoute from './AuthenticatedRoute';
import MyBookings from './MyBookings';
import MyRedemptions from './MyRedemptions';
import MyHolidaycheck from './MyHolidaycheck';
import MyBadges from './MyBadges';
import MyReviews from './MyReviews';
import MyMedia from './MyMedia';
import ProfilePicturePage from './myProfile/ProfilePicturePage';
import SettingsPage from './myProfile/SettingsPage';
import ChangeEmailPage from './myProfile/ChangeEmailPage';
import MyMessages from './MyMessages';
import MyWatchlist from './MyWatchlist';
import PictureDetailView from './pictureDetailView/PictureDetailView';
import MyPremium from './MyPremium';
import PremiumCancellationPage from './myPremium/CancellationPage';
import BookingView from './myBookings/BookingView';
import MyMietwagenBookings from './MyMietwagenBookings';
import MyMietwagenBooking from './MyMietwagenBooking';

const Routes = () => {
    return h(ReactRouterRoutes, [
        h(Route, {
            path: 'mhc/mein-holidaycheck',
            element: h(AuthenticatedRoute, [h(MyHolidaycheck)]),
        }),
        h(
            Route,
            {
                path: '/mhc/meine-urlaubsbilder',
                element: h(AuthenticatedRoute, [h(MyMedia)]),
            },
            h(Route, {
                path: '/mhc/meine-urlaubsbilder/:pictureId',
                element: h(AuthenticatedRoute, [h(PictureDetailView)]),
            }),
        ),
        h(Route, {
            path: '/mhc/meine-gutscheine',
            element: h(AuthenticatedRoute, [h(MyRedemptions)]),
        }),
        h(Route, {
            path: '/mhc/meine-bewertungen',
            element: h(AuthenticatedRoute, [h(MyReviews)]),
        }),
        h(Route, {
            path: '/mhc/meine-buchungen',
            element: h(AuthenticatedRoute, [h(MyBookings)]),
        }),
        h(Route, {
            path: '/mhc/meine-buchungen/:bookingId',
            element: h(AuthenticatedRoute, [h(BookingView)]),
        }),
        h(Route, {
            path: '/mhc/mein-holidaycheck',
            element: h(AuthenticatedRoute, [h(MyHolidaycheck)]),
        }),
        h(Route, {
            path: '/mhc/mein-mietwagen/buchungen',
            element: h(AuthenticatedRoute, [h(MyMietwagenBookings)]),
        }),
        h(Route, {
            path: '/mhc/mein-mietwagen/buchungen/:bookingId',
            element: h(AuthenticatedRoute, [h(MyMietwagenBooking)]),
        }),
        h(Route, {
            path: '/mhc/meine-auszeichnungen',
            element: h(AuthenticatedRoute, [h(MyBadges)]),
        }),
        h(Route, {
            path: '/mhc/mein-premium',
            element: h(AuthenticatedRoute, [h(MyPremium)]),
        }),
        h(Route, {
            path: '/mhc/mein-premium/kuendigen',
            element: h(AuthenticatedRoute, [h(PremiumCancellationPage)]),
        }),
        h(Route, {
            path: '/mhc/mein-profil',
            element: h(AuthenticatedRoute, [h(SettingsPage)]),
        }),
        h(Route, {
            path: '/mhc/mein-profil/profilbild',
            element: h(AuthenticatedRoute, [h(ProfilePicturePage)]),
        }),
        h(Route, {
            path: '/mhc/mein-profil/email-aendern',
            element: h(AuthenticatedRoute, { isAuthorizationRequired: true }, [
                h(ChangeEmailPage),
            ]),
        }),
        h(Route, {
            path: '/mhc/meine-nachrichten',
            element: h(AuthenticatedRoute, [h(MyMessages)]),
        }),
        h(Route, {
            path: '/mhc/meine-beobachtungen',
            element: h(AuthenticatedRoute, [h(MyWatchlist)]),
        }),
    ]);
};

export default Routes;
