import { hasOriginalDeposit } from './paymentConditionsHelpers';

const getTourOperatorText = (tourOperatorName) => {
    return tourOperatorName ? tourOperatorName : 'Deinem Reiseveranstalter';
};

const getFallbackIntroText = (tourOperatorText) =>
    'Deine Buchungsbestätigung/Rechnung ' +
    `erhältst Du innerhalb von 48 Stunden nach der Buchung von ${tourOperatorText} per E-Mail. Bitte prüfe ggfs. auch ` +
    'Deinen Spamordner. Beachte: die Buchungsbestätigung ist gleichzeitig Deine Rechnung. ' +
    'Du erhältst keine separate Rechnung.';

const fallbackHints = {
    CREDIT_CARD:
        'Bitte prüfe, ob Deine Kreditkarte ausreichend gedeckt ist, ' +
        'damit eine reibungslose Abbuchung erfolgen kann.',
    TRANSFER:
        'Sollte die Zahlung nicht rechtzeitig eingegangen sein, meldet sich der Veranstalter bei Dir.',
    DIRECT_DEBIT_SEPA:
        'Für einen reibungslosen Ablauf stell bitte sicher, ' +
        'dass Dein Konto über ausreichend Guthaben verfügt.',
    IN_HOTEL:
        'Im Normalfall zahlst du den Reisepreis direkt im Hotel. ' +
        'Die angegebene Kreditkarte wird vom Hotel als Garantie benötigt. ' +
        'In Einzelfällen mit Spezialtarifen wird die Kreditkarte sofort belastet.',
};

const getFallbackHint = (paymentType = {}) => {
    return fallbackHints[paymentType] ? fallbackHints[paymentType] : '';
};

const getCreditCardText = (hasDepositInBooking, tourOperator) => {
    if (!hasDepositInBooking) {
        return (
            'Deine Buchungsbestätigung/Rechnung erhältst Du innerhalb von 48 Stunden ' +
            `nach der Buchung von ${tourOperator} per E-Mail. Bitte prüfe ggfs. auch Deinen Spamordner. Nach Erhalt ` +
            `der Buchungsbestätigung/Rechnung von ${tourOperator}, wird der Reisepreis wie ausgewiesen, automatisch ` +
            'von Deiner hinterlegten Kreditkarte abgebucht.'
        );
    }

    return (
        'Deine Buchungsbestätigung/Rechnung erhältst Du innerhalb von 48 Stunden ' +
        `nach der Buchung von ${tourOperator} per E-Mail. Bitte prüfe ggfs. auch Deinen Spamordner. Nach Erhalt der ` +
        `Buchungsbestätigung/Rechnung von ${tourOperator}, werden Dir die im Folgenden genannten Beträge ` +
        'automatisch von Deiner hinterlegten Kreditkarte abgebucht:'
    );
};

const getTransferText = (hasDepositInBooking, tourOperator) => {
    if (!hasDepositInBooking) {
        return (
            'Deine Buchungsbestätigung/Rechnung erhältst Du innerhalb von 48 Stunden ' +
            `nach der Buchung von ${tourOperator} per E-Mail. Bitte prüfe ggfs. auch Deinen Spamordner. Beachte: ` +
            'die Buchungsbestätigung ist gleichzeitig Deine Rechnung. Du erhälst keine separate Rechnung.'
        );
    }

    return (
        'Deine Buchungsbestätigung/Rechnung erhältst Du innerhalb von 48 Stunden ' +
        `nach der Buchung von ${tourOperator} per E-Mail. Bitte prüfe ggfs. auch Deinen Spamordner und beachte, ` +
        'dass die Buchungsbestätigung Deine Rechnung ist und Du keine weitere Rechnung erhältst. Bitte folge den ' +
        `Zahlungsanweisungen in der E-Mail von ${tourOperator} und überweise folgende Beträge`
    );
};

const getSepaText = (hasDepositInBooking, tourOperator) => {
    if (!hasDepositInBooking) {
        return (
            'Deine Buchungsbestätigung/Rechnung erhältst Du innerhalb von 48 Stunden ' +
            `nach der Buchung von ${tourOperator} per E-Mail. Bitte prüfe ggfs. auch Deinen Spamordner. Nach ` +
            `Erhalt der Buchungsbestätigung/Rechnung von ${tourOperator}, wird der Reisepreis wie ausgewiesen, ` +
            'automatisch von Deiner hinterlegten Bankverbindung abgebucht.'
        );
    }

    return (
        'Deine Buchungsbestätigung/Rechnung erhältst Du innerhalb von 48 Stunden ' +
        `nach der Buchung von ${tourOperator} per E-Mail. Bitte prüfe ggfs. auch Deinen Spamordner. Nach Erhalt der ` +
        `Buchungsbestätigung/Rechnung von ${tourOperator}, werden Dir die im Folgenden genannten Beträge automatisch ` +
        'von Deiner hinterlegten Bankverbindung abgebucht:'
    );
};

const getPaymentConditionsIntroText = (
    paymentType,
    tourOperatorName,
    tourOperatorPrice = {},
) => {
    const tourOperator = getTourOperatorText(tourOperatorName);
    const hasDepositInBooking = hasOriginalDeposit(tourOperatorPrice);

    switch (paymentType) {
        case 'CREDIT_CARD':
            return getCreditCardText(hasDepositInBooking, tourOperator);
        case 'TRANSFER':
            return getTransferText(hasDepositInBooking, tourOperator);
        case 'DIRECT_DEBIT_SEPA':
            return getSepaText(hasDepositInBooking, tourOperator);
        default:
            return getFallbackIntroText(tourOperator);
    }
};

const getCancelledTravelText = () => {
    return (
        'Bisher geleistete Zahlungen werden mit den Stornokosten verrechnet. Falls sich ein Guthaben ergibt, ' +
        'wird die Rückerstattung vollständig durch den Veranstalter abgewickelt. Alle Informationen hierzu sind in ' +
        'der Stornobestätigung des Veranstalters zu finden.'
    );
};

export {
    getPaymentConditionsIntroText,
    getFallbackIntroText,
    getFallbackHint,
    getCancelledTravelText,
};
