import glamorous from 'glamorous';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Mutation } from '@apollo/client/react/components';
import options from '@designsystem/options';

import Link from '../../navigation/Link';
import ProfilePictureFileInput from '../../inputs/ProfilePictureFileInput';
import dataURIUtil from '../../../lib/dataURIUtil';
import { updateProfile as updateProfileMutation } from '../../../mutators/profile';
import { wasMutationSuccessful } from '../../../lib/updateProfileMutation';
import { waveXsOnlyMediaQuery } from '../../../styles/waveMediaQueries';

const PictureContainer = glamorous.figure({
    textAlign: 'center',
    margin: 0,
    marginTop: options.space.l,
    position: 'relative',
    height: '10rem',
    [waveXsOnlyMediaQuery]: {
        height: '7rem',
    },

    '&::after': {
        background: options.colors.white,
        bottom: 0,
        content: '""',
        left: 0,
        position: 'absolute',
        right: 0,
        top: '50%',
    },
});

const ProfilePicture = glamorous.img({
    border: `3px solid ${options.colors.white}`,
    borderRadius: '50%',
    boxShadow: `0 0 1px ${options.colors.gray40}`,
    boxSizing: 'border-box',
    height: '10rem',
    position: 'relative',
    width: '10rem',
    zIndex: 1,

    [waveXsOnlyMediaQuery]: {
        height: '7rem',
        width: '7rem',
    },
});

const PictureOptions = glamorous.div({
    background: options.colors.white,
    textAlign: 'center',
    paddingTop: options.space.xs,
    paddingBottom: options.space['2xl'],
});

const DeleteLink = glamorous(Link.withComponent('button'))({
    fontFamily: 'Open Sans, sans-serif',
    display: 'inline-block',
    padding: `0 0 0 ${options.space.xs}px`,
    margin: `0 0 0 ${options.space.xs}px`,
    lineHeight: 0.8,

    '&:not(:first-child)': {
        borderLeft: `1px solid ${options.colors.black}`,
    },
});

const MotivationClaim = glamorous.aside({
    fontSize: options.space.s,
    textAlign: 'center',
    color: options.colors.gray90,
    marginTop: options.space.s,
});

class ProfilePictureSetting extends Component {
    constructor(...args) {
        super(...args);

        this.navigateToProfilePicturePage =
            this.navigateToProfilePicturePage.bind(this);
        this.renderDeletePictureLink = this.renderDeletePictureLink.bind(this);
    }

    navigateToProfilePicturePage({ profilePicture }) {
        const { window, profilePictureStore, trackEvent } = this.context;
        const isValidPictureType =
            dataURIUtil.isJPEG(profilePicture) ||
            dataURIUtil.isPNG(profilePicture);

        if (isValidPictureType) {
            profilePictureStore.set(window, { profilePicture });
            this.props.navigate('/mhc/mein-profil/profilbild');
        } else {
            trackEvent({
                event: 'event',
                eventCategory: 'MHC',
                eventAction: 'profile picture',
                eventLabel: `update failure | unexpected filetype ${dataURIUtil.getMimeType(
                    profilePicture,
                )}`,
            });

            window.alert('Bitte verwenden Sie nur jpg- oder png-Dateien.');
        }
    }

    renderDeletePictureLink(updateProfile, mutationState) {
        if (wasMutationSuccessful(mutationState)) {
            this.props.onDeletedProfilePicture();
        }

        return h(
            DeleteLink,
            {
                type: 'button',
                onClick() {
                    updateProfile({
                        variables: { input: { profilePicture: null } },
                    });
                },
            },
            'Profilbild löschen',
        );
    }

    renderChangePictureLink(label) {
        return h(ProfilePictureFileInput, {
            onChange: this.navigateToProfilePicturePage,
            label,
        });
    }

    renderNoProfilePictureOptions() {
        return h([
            this.renderChangePictureLink('Profilbild hinzufügen'),
            h(
                MotivationClaim,
                'Beiträge mit eigenem Profilbild wirken authentischer.',
            ),
        ]);
    }

    renderProfilePictureOptions() {
        return h([
            this.renderChangePictureLink('Profilbild ändern'),
            h(
                Mutation,
                { mutation: updateProfileMutation },
                this.renderDeletePictureLink,
            ),
        ]);
    }

    render() {
        const { picture } = this.props;
        const hasPicture = picture && !picture.isFallback;
        const src =
            (picture &&
                picture.transformations &&
                picture.transformations.medium) ||
            undefined;

        return h([
            h(PictureContainer, {}, [h(ProfilePicture, { src })]),
            h(PictureOptions, {}, [
                hasPicture
                    ? this.renderProfilePictureOptions()
                    : this.renderNoProfilePictureOptions(),
            ]),
        ]);
    }
}

ProfilePictureSetting.propTypes = {
    picture: PropTypes.object,
    onDeletedProfilePicture: PropTypes.func,
    navigate: PropTypes.func.isRequired,
};

ProfilePictureSetting.defaultProps = {
    picture: null,
};

ProfilePictureSetting.contextTypes = {
    window: PropTypes.object,
    profilePictureStore: PropTypes.object,
    trackEvent: PropTypes.func,
};

export default ProfilePictureSetting;
