import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import ProfilePictureForm from './ProfilePictureForm';
import Column from '../grid/Column';
import Page from '../Page';
import { waveSmMediaQuery } from '../../styles/waveMediaQueries';

const Content = glamorous.div({
    alignItems: 'center',
    background: options.colors.gray100,
    display: 'flex',
    flexDirection: 'column',
    marginTop: options.space.xl,
    paddingBottom: options.space['2xl'],
});

const HelpText = glamorous.p({
    margin: `${options.space.m}px 0`,
    padding: 0,
    textAlign: 'center',
    fontSize: options.fontSizes.xs,
    [waveSmMediaQuery]: {
        margin: `${options.space.l}px 0`,
    },
});

function ProfilePicturePage() {
    return h(
        Page,
        {
            headline: 'Einstellungen',
            pagename: '/mhc/profilepicturestart',
        },
        [
            h(Content, [
                h(Column, { small: null, medium: 6, large: 6 }, [
                    h(HelpText, 'Wählen Sie den besten Bildausschnitt.'),
                    h(ProfilePictureForm),
                ]),
            ]),
        ],
    );
}

export default ProfilePicturePage;
