import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Fragment, useMemo, useEffect } from 'react';
import moment from 'moment';
import options from '@designsystem/options';

import { hasStarted } from '../../../common/lib/bookingHelper';
import { superLoudFont, secondaryFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';
import { gray90 } from '../../styles/waveColors';
import { applyOnDesktop } from '../../styles/mediaQueries';
import buttons from '../../styles/buttons';
import RightArrowLine from '../icons/RightArrowLine';
import {
    isCancellationInsurance,
    isHealthInsurance,
} from './lib/serviceHelpers';
import BookingSectionHeadline from './BookingSectionHeadline';
import useBookingContext from './lib/useBookingContext';
import {
    getCancellationUrl,
    getHealthInsuranceUrl,
} from './lib/hanseMerkurHelper';
import { waveTextLBold, waveTextM, waveTextS } from '../../styles/waveText';
import PremiumLabel from '../myHolidaycheck/PremiumLabel';
import PremiumHealthInsuranceFooter from './PremiumHealthInsuranceFooter';

const { PTSansFamily } = secondaryFont;
const { blueButton } = buttons;
const Teasers = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
});
Teasers.displayName = 'Teasers';

const Teaser = glamorous.div({
    position: 'relative',
    border: `1px solid ${gray90}`,
    borderRadius: pxToRem(4),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '&:not(:last-child)': {
        marginBottom: pxToRem(24),
    },
    [applyOnDesktop]: {
        width: `calc(50% - ${pxToRem(12)})`,
        '&:not(:last-child)': {
            marginBottom: 0,
        },
    },
});
Teaser.displayName = 'Teaser';

const TeaserContent = glamorous.div({
    padding: pxToRem(options.space.m),
    paddingTop: pxToRem(options.space.xl),
});
TeaserContent.displayName = 'TeaserContent';

const Name = glamorous.h4(superLoudFont, {
    ...waveTextLBold,
    marginBottom: pxToRem(4),
    marginTop: 0,
});
Name.displayName = 'Name';

const Description = glamorous.div(PTSansFamily, {
    ...waveTextM,
});
Description.displayName = 'Description';

const Bar = glamorous.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: pxToRem(4),
    flexWrap: 'wrap',
    borderTop: `1px solid ${gray90}`,
    padding: options.space.m,
});
Bar.displayName = 'Bar';

const PremiumBar = glamorous.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: pxToRem(4),
    backgroundColor: options.colors.purple100,
    padding: options.space.m,
    flexDirection: 'row',
    borderTop: `1px solid ${gray90}`,
});
PremiumBar.displayName = 'PremiumBar';

const PremiumBarText = glamorous.div(PTSansFamily, {
    ...waveTextM,
});
PremiumBarText.displayName = 'PremiumBarText';

const Link = glamorous.a(blueButton, {});
Link.displayName = 'Link';

const Logo = glamorous.img({
    height: pxToRem(40),
});
Logo.displayName = 'Logo';
const Services = glamorous.ul(({ assetsPath }) => ({
    listStyleImage: `url('${assetsPath}/myBookings/plus.svg')`,
    listStylePosition: 'inside',
    paddingInlineStart: pxToRem(3),
    marginBottom: 'auto',
}));
Services.displayName = 'Services';

const Service = glamorous.li({
    ...waveTextS,
    marginBottom: pxToRem(4),
});
Service.displayName = 'Service';

const renderBar = (trackEventWithBooking, eventLabel, url, assetsPath) => {
    return h(Bar, [
        h(
            Link,
            {
                href: url,
                target: '_blank',
                onClick: trackEventWithBooking.bind(null, {
                    event: 'event',
                    eventCategory: 'mybooking',
                    eventAction: 'insurance-clicked',
                    eventLabel,
                }),
            },
            [
                h('div', 'Dazubuchen'),
                h(RightArrowLine, {
                    css: {
                        height: pxToRem(20),
                        marginLeft: pxToRem(8),
                        marginRight: pxToRem(-6),
                    },
                }),
            ],
        ),
        h(Logo, {
            src: `${assetsPath}/myBookings/hanse-merkur.svg`,
            alt: '',
        }),
    ]);
};

const renderCancellationInsurance = ({
    booking,
    trackEventWithBooking,
    topLevelDomain,
    assetsPath,
    currentDate,
}) => {
    return h(Teaser, [
        h(TeaserContent, [
            h(Name, 'Reiseschutz'),
            h(
                Description,
                'Die Hanse Merkur bietet einen weltweit zuverlässigen Reiseschutz u.a. bei:',
            ),
            h(Services, { assetsPath }, [
                h(Service, 'Reiserücktritt'),
                h(Service, 'Vorzeitigem Reiseabbruch'),
            ]),
        ]),
        renderBar(
            trackEventWithBooking,
            'cancellation',
            getCancellationUrl(booking, topLevelDomain, currentDate),
            assetsPath,
        ),
    ]);
};

const renderHealthInsurance = ({
    booking,
    trackEventWithBooking,
    topLevelDomain,
    assetsPath,
    currentDate,
}) => {
    return h(Teaser, [
        h(Name, 'Reise-Krankenversicherung'),
        h(
            Description,
            'Wir übernehmen u.a. alle Kosten für eine medizinische Versorgung im Ausland!',
        ),
        h(Services, { assetsPath }, [
            h(Service, 'Krankheit im Ausland'),
            h(Service, 'inklusive 24-Stunden-Notfallservice'),
        ]),

        renderBar(
            trackEventWithBooking,
            'health',
            getHealthInsuranceUrl(booking, topLevelDomain, currentDate),
            assetsPath,
        ),
    ]);
};

const renderPremiumHealthInsurance = ({ assetsPath, booking }) => {
    return h(Teaser, [
        h(PremiumLabel, {
            style: {
                position: 'absolute',
                top: -options.space.s,
                left: options.space.m,
            },
        }),
        h(TeaserContent, [
            h(Name, 'Auslandskrankenversicherung'),
            h(
                Description,
                'Wir übernehmen u.a. alle Kosten für eine medizinische Versorgung im Ausland!',
            ),
            h(Services, { assetsPath }, [
                h(Service, 'Krankheit im Ausland'),
                h(Service, 'inklusive 24-Stunden-Notfallservice'),
            ]),
        ]),
        h(PremiumHealthInsuranceFooter, { booking }),
    ]);
};

const hasInsuranceBooked = (services) => {
    return services.find(isCancellationInsurance);
};

const isCancellationInsurancePossible = (
    currentDate,
    creationDate,
    startDate,
) => {
    const daysBetweenBookingAndCurrentDate = moment(currentDate).diff(
        creationDate,
        'days',
    );
    const daysBetweenCurrentAndDepartureDate = moment(startDate).diff(
        currentDate,
        'days',
    );
    const daysBetweenBookingAndDepartureDate = moment(startDate).diff(
        creationDate,
        'days',
    );

    // For short term bookings(travel starts less than 30 days from the date of booking),
    // insurance is always allowed the first three days after booking
    if (
        daysBetweenBookingAndDepartureDate < 30 &&
        daysBetweenBookingAndCurrentDate <= 3
    ) {
        return true;
    }

    return daysBetweenCurrentAndDepartureDate >= 30;
};

const isAnyInsurancePossible = (booking, currentDate) => {
    return (
        !hasInsuranceBooked(booking.services) &&
        !hasStarted(booking, currentDate) &&
        booking.bookingType === 'BOOKING'
    );
};

const trackVisibleInsurances = (
    trackEventWithBooking,
    anyInsurancePossible,
    cancellationInsurancePossible,
) => {
    const visibles = anyInsurancePossible ? ['health'] : [];
    if (cancellationInsurancePossible) {
        visibles.push('cancellation');
    }
    visibles.forEach((visible) => {
        trackEventWithBooking({
            event: 'event',
            eventCategory: 'mybooking',
            eventAction: 'insurance-shown',
            eventLabel: visible,
        });
    });
};

// eslint-disable-next-line max-statements
const InsuranceUpsell = (
    { booking, myRef, setWillRender },
    { config: { topLevelDomain, assetsPath }, getCurrentDate },
) => {
    const currentDate = getCurrentDate();
    const anyInsurancePossible = useMemo(
        () => isAnyInsurancePossible(booking, currentDate),
        [booking, currentDate],
    );
    const cancellationInsurancePossible = useMemo(
        () =>
            anyInsurancePossible &&
            isCancellationInsurancePossible(
                currentDate,
                booking.creationDate,
                booking.startDate,
            ),
        [
            anyInsurancePossible,
            currentDate,
            booking.creationDate,
            booking.startDate,
        ],
    );
    const { trackEventWithBooking } = useBookingContext();

    useEffect(() => {
        trackVisibleInsurances(
            trackEventWithBooking,
            anyInsurancePossible,
            cancellationInsurancePossible,
        );
    }, [
        trackEventWithBooking,
        anyInsurancePossible,
        cancellationInsurancePossible,
    ]);

    if (!anyInsurancePossible) {
        return null;
    }

    useEffect(() => setWillRender(true), []);

    const teasers = [
        booking.services.some(isHealthInsurance)
            ? renderPremiumHealthInsurance({
                  assetsPath,
                  booking,
              })
            : renderHealthInsurance({
                  booking,
                  trackEventWithBooking,
                  topLevelDomain,
                  assetsPath,
                  currentDate,
              }),
    ];

    if (cancellationInsurancePossible) {
        teasers.unshift(
            renderCancellationInsurance({
                booking,
                trackEventWithBooking,
                topLevelDomain,
                assetsPath,
                currentDate,
            }),
        );
    }

    return h(Fragment, [
        h(
            BookingSectionHeadline,
            { innerRef: myRef, id: 'versicherung' },
            'Deinen Urlaub absichern',
        ),
        h(Teasers, teasers),
    ]);
};

InsuranceUpsell.propTypes = {
    booking: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        creationDate: PropTypes.string.isRequired,
        bookingType: PropTypes.string.isRequired,
        travellers: PropTypes.array,
        tourOperatorPrice: PropTypes.shape({
            amount: PropTypes.number.isRequired,
        }),
        services: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

InsuranceUpsell.contextTypes = {
    config: PropTypes.shape({
        topLevelDomain: PropTypes.string.isRequired,
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
    getCurrentDate: PropTypes.func.isRequired,
};

InsuranceUpsell.displayName = 'InsuranceUpsell';

export default InsuranceUpsell;
