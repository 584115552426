import React from 'react';
import h from 'react-hyperscript';
import * as PropTypes from 'prop-types';
import options from '@designsystem/options';

import Column from '../../grid/Column';
import TextInput from '../../inputs/TextInput';
import Link from '../../navigation/Link';

const renderSupportLink = () => {
    const supportEmailAddress = 'community@holidaycheck.de';
    return h(
        Link,
        { href: `mailto:${supportEmailAddress}` },
        supportEmailAddress,
    );
};

const renderInfoMessage = (initialMilesNumber) => {
    if (initialMilesNumber) {
        return h([
            'Falls Sie Ihre bestehende Kartennummer ändern wollen, wenden Sie sich bitte an ',
            renderSupportLink(),
        ]);
    }
    return 'Deine 15-stellige Miles & More Kartennummer, um Meilen zu sammeln';
};

const renderErrorMessage = (
    isValid,
    showValidationErrorMessage,
    mutationResult,
) => {
    if (!isValid && showValidationErrorMessage) {
        return 'Die Kartennummer muss aus 15 Ziffern bestehen';
    }
    if (mutationResult === 'ERROR_CONFLICTING_MILES_NUMBER') {
        return h([
            'Es existiert bereits ein anderer Zugang mit dieser Kartennummer. Bitte wenden Sie sich an ',
            renderSupportLink(),
            ', falls Sie Ihre beiden Zugänge zusammenführen wollen.',
        ]);
    }
    return null;
};

class MilesNumberSetting extends React.PureComponent {
    constructor(...args) {
        super(...args);
        this.state = {
            isValid: true,
            showValidationErrorMessage: false,
        };

        this.updateValidationState = this.updateValidationState.bind(this);
        this.updateErrorMessageState = this.updateErrorMessageState.bind(this);
    }

    updateValidationState(value, onChange) {
        if (value === '') {
            this.setState({ isValid: true });
            onChange(null);
            return;
        }
        const result = /^[0-9]{15}$/.test(value);
        this.setState({ isValid: result });
        onChange(value);
    }

    updateErrorMessageState() {
        this.setState({ showValidationErrorMessage: !this.state.isValid });
    }

    render() {
        const {
            milesNumber,
            onChange,
            disabled = false,
            initialMilesNumber,
            mutationResult,
        } = this.props;
        const { isValid, showValidationErrorMessage } = this.state;

        return h(
            Column,
            {
                style: { paddingBottom: options.space.l },
                small: null,
                medium: null,
                large: 6,
            },
            [
                h(TextInput, {
                    name: 'milesNumber',
                    label: 'Miles & More Kartennummer',
                    placeholder: '',
                    infoMessage: renderInfoMessage(initialMilesNumber),
                    onChange: (newValue) =>
                        this.updateValidationState(newValue, onChange),
                    onBlur: this.updateErrorMessageState,
                    value: milesNumber || '',
                    disabled: disabled || Boolean(initialMilesNumber),
                    errorMessage: renderErrorMessage(
                        isValid,
                        showValidationErrorMessage,
                        mutationResult,
                    ),
                }),
            ],
        );
    }
}

MilesNumberSetting.propTypes = {
    milesNumber: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    initialMilesNumber: PropTypes.string,
    mutationResult: PropTypes.string,
};

export default MilesNumberSetting;
