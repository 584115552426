import h from 'react-hyperscript';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/client/react/components';
import { Navigate } from 'react-router-dom';

import Page from '../Page';
import ChangeEmailForm from './ChangeEmailForm';
import { email as emailQuery } from '../../queries/profile';
import { updateProfile as updateProfileMutation } from '../../mutators/profile';
import {
    getMutationResult,
    wasMutationSuccessful,
} from '../../lib/updateProfileMutation';
import ManualMergeRequestSuccessPage from './ManualMergeRequestSuccessPage';

function scrollToTop(window) {
    window.scrollTo(0, 0);
}

class ChangeEmailPage extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            changedEmail: null,
        };

        this.renderForm = this.renderForm.bind(this);
    }

    componentDidMount() {
        scrollToTop(this.context.window);
    }

    renderForm(queryState) {
        let email = '';

        if (!queryState.loading && !queryState.error) {
            email = queryState.data.profile.email;
        }

        return h(
            Mutation,
            { mutation: updateProfileMutation },
            (updateProfile, mutationState) => {
                const result = getMutationResult(mutationState);
                if (wasMutationSuccessful(mutationState)) {
                    if (result === 'SUCCESS_MERGE_REQUEST') {
                        this.context.localStorage.setItem(
                            'manualMergeRequest',
                            this.state.changedEmail,
                        );
                        return h(ManualMergeRequestSuccessPage);
                    }

                    return h(Navigate, {
                        to: '/logout?ref=%2Flogin%3Fsuccess%3DemailChanged',
                        replace: true,
                    });
                }
                const previousManualMergeRequest =
                    this.context.localStorage.getItem('manualMergeRequest');
                if (
                    result === 'ERROR_CONFLICTING_EMAIL' &&
                    previousManualMergeRequest &&
                    previousManualMergeRequest === this.state.changedEmail
                ) {
                    return h(ManualMergeRequestSuccessPage);
                }

                return h(ChangeEmailForm, {
                    isRequestRunning: mutationState.loading,
                    result,
                    email,
                    onChange: ({ changedEmail, mergeWithEmail }) => {
                        this.setState({
                            changedEmail: changedEmail || mergeWithEmail,
                        });

                        const variables = {
                            input: { email: changedEmail },
                        };

                        if (mergeWithEmail) {
                            variables.input = { mergeWithEmail };
                        }

                        updateProfile({ variables });
                    },
                });
            },
        );
    }

    render() {
        const pageProps = {
            headline: 'Einstellungen',
            pagename: '/mhc/emailupdate',
        };

        return h(Page, pageProps, [
            h(
                Query,
                { fetchPolicy: 'cache-and-network', query: emailQuery },
                this.renderForm,
            ),
        ]);
    }
}

ChangeEmailPage.contextTypes = {
    window: PropTypes.object,
    localStorage: PropTypes.object.isRequired,
};

export default ChangeEmailPage;
