import h from 'react-hyperscript';
import glamorous from 'glamorous';

import Link from '../navigation/Link';
import ButtonLink from '../navigation/ButtonLink';
import Upload from '../illustrations/Upload';
import UploadSmall from '../illustrations/Upload-small';
import {
    mediumDistance,
    largeDistance,
    xxxlargeDistance,
} from '../../styles/distances';
import { bigLoudFont, subheadlineFont } from '../../styles/fonts';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';

const NoAlbumsContainer = glamorous.div({
    textAlign: 'center',
    paddingTop: largeDistance,
    paddingBottom: xxxlargeDistance,

    [applyOnTabletAndUp]: {
        paddingTop: pxToRem(65),
        paddingBottom: pxToRem(78),
    },
});

const IllustrationLarge = glamorous.span({
    display: 'none',

    '> svg': {
        height: pxToRem(97),
        width: pxToRem(122),
    },

    [applyOnTabletAndUp]: {
        display: 'block',
    },
});

const IllustrationSmall = glamorous.span({
    display: 'block',

    '> svg': {
        height: pxToRem(70),
        width: pxToRem(90),
    },

    [applyOnTabletAndUp]: {
        display: 'none',
    },
});

const InfoText = glamorous.p({
    ...bigLoudFont,
    margin: `${mediumDistance} auto 2.5rem`,
    maxWidth: pxToRem(290),

    [applyOnTabletAndUp]: {
        ...subheadlineFont,
        marginTop: largeDistance,
        maxWidth: pxToRem(520),
    },
});

export default function NoAlbums() {
    return h(NoAlbumsContainer, [
        h(
            Link,
            {
                href: '/wcf/hotel/image',
                css: {
                    display: 'inline-block',
                },
            },
            [
                h(IllustrationLarge, [h(Upload)]),
                h(IllustrationSmall, [h(UploadSmall)]),
            ],
        ),
        h(
            InfoText,
            'Laden Sie jetzt Ihre ersten Urlaubsbilder hoch und zeigen Sie, wie es vor Ort wirklich aussieht!',
        ),
        h(
            ButtonLink,
            {
                href: '/wcf/hotel/image',
            },
            'Bilder hochladen',
        ),
    ]);
}
