import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Phone({ css }) {
    return h(
        Icon,
        {
            viewBox: '0 0 20 20',
            css,
        },
        [
            h('path', {
                d: oneLine`M5.05196 2.2999C3.65994 2.2999 2.66902 3.34235 2.4223 4.9688C2.28922 5.84613 2.18039 7.06545
             2.57479 8.55086C2.9688 10.0348 3.87881 11.8345 5.86125 13.8393C9.80089 17.8233 13.3192 17.8658 14.9598
              17.613C15.7731 17.4876 16.466 17.1789 16.9446 16.7333C17.4099 16.3002 17.7004 15.7149 17.7004
               14.9446C17.7004 13.4724 16.5715 12.6388 15.3099 12.6388C14.1142 12.6388 13.1221 13.5553 13.0017
                14.7398C12.9785 14.9683 12.8446 15.1709 12.6435 15.2819C12.4424 15.3929 12.1997 15.3981 11.9939
                 15.296C10.3072 14.4583 8.95958 13.6377 7.79437 12.5047C6.62698 11.3697 5.67757 9.9555 4.73178
                  7.97392C4.63376 7.76855 4.64168 7.52835 4.75303 7.32989C4.86437 7.13143 5.06524 6.99948 5.2916
                   6.9761C6.45584 6.85587 7.37246 5.86137 7.37246 4.64415C7.37246 3.4064 6.46402 2.2999 5.05196
                    2.2999ZM1.03813 4.75883C1.35259 2.68581 2.75203 0.899902 5.05196 0.899902C7.33185 0.899902
                     8.77246 2.73136 8.77246 4.64415C8.77246 6.23838 7.78127 7.60401 6.37979 8.14284C7.14498 9.6111
                      7.90256 10.6572 8.77032 11.501C9.61602 12.3233 10.5906 12.9772 11.8358 13.6411C12.3713
                       12.2381 13.7219 11.2388 15.3099 11.2388C17.1743 11.2388 19.1004 12.5391 19.1004
                        14.9446C19.1004 16.1129 18.6415 17.0664 17.8986 17.758C17.169 18.4372 16.1949 18.8392 15.173
                         18.9966C13.1429 19.3094 9.15763 19.1639 4.86577 14.8237C2.73246 12.6663 1.68553 10.6571
                          1.22168 8.91013C0.758225 7.16467 0.890631 5.73122 1.03813 4.75883Z`,
            }),
        ],
    );
}
